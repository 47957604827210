import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-single-paiement',
  templateUrl: './feature-single-paiement.component.html',
  styleUrls: ['./feature-single-paiement.component.scss']
})
export class FeatureSinglePaiementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
