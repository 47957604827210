import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';

// 1. Import the libs you need
import { AngularFireModule } from '@angular/fire';

// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

// 2. Add your credentials from step 1
const config = {
  apiKey: "AIzaSyAqeJ27PaIkt85iv9VdCjEkWxURL4Hhv54",
  authDomain: "eatz-f56f4.firebaseapp.com",
  databaseURL: "https://eatz-f56f4.firebaseio.com",
  projectId: "eatz-f56f4",
  storageBucket: "eatz-f56f4.appspot.com",
  messagingSenderId: "271812087485",
  appId: "1:271812087485:web:c30c0759a7e6483405f1d4",
  measurementId: "G-8K57Q7JNWL"
};


import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './modules/home/home.module';

import { TermsComponent } from './modules/home/terms/terms.component';
import { PrivacyComponent } from './modules/home/privacy/privacy.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    TermsComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),

    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    // 3. Initialize
    AngularFireModule.initializeApp(config),

    AppRoutingModule,
    HomeModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
