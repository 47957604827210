<div class="stats-disp">
  <div class="stat-box">
    <p class="stat-number">10</p>
    <p class="stat-description">{{ 'landing.exp-5' | translate }}</p>
  </div>
  <div class="stat-box">
    <p class="stat-number">+100</p>
    <p class="stat-description">{{ 'landing.exp-3' | translate }}</p>
  </div>
  <div class="stat-box">
    <p class="stat-number">+140</p>
    <p class="stat-description">{{ 'landing.exp-4' | translate }}</p>
  </div>
  <div class="stat-box">
    <p class="stat-number">+5 000</p>
    <p class="stat-description">{{ 'landing.exp-2' | translate }}</p>
  </div>
</div>
