<div class="carousel-disp" [ngStyle]="{'margin-left': number1+'px'}">
  <div class="car-block" style="background-color: var(--eatz-vert-clair);"><img class="img-mockup" src="/../assets/img/landing-page/menu-iphone-x.png" alt="Photo EAt'Z"></div>
  <div class="car-block"><img [ngStyle]="{'transform': 'scale('+scale+')'}" class="img-fill" src="/../assets/img/landing-page/DSC02903-140.JPG" alt="Photo EAt'Z"></div>
  <div class="car-block" style="background-color: var(--eatz-vert-clair);"><img class="img-mockup" src="/../assets/img/landing-page/lou-ginestre-menu.png"></div>
  <div class="car-block"><img [ngStyle]="{'transform': 'scale('+scale+')'}" class="img-fill" src="/../assets/img/landing-page/DSC03095-144.JPG" alt="Photo EAt'Z"></div>
</div>

<div class="carousel-disp" [ngStyle]="{'padding-left': number+'px'}">
  <div class="car-block"><img [ngStyle]="{'transform': 'scale('+scale+')'}" class="img-fill" src="/../assets/img/landing-page/DSC02856-137.JPG" alt="Photo EAt'Z"></div>
  <div class="car-block"><img class="img-mockup" src="/../assets/img/landing-page/florence-khan-menu.png"></div>
  <div class="car-block"><img [ngStyle]="{'transform': 'scale('+scale+')'}" class="img-fill" src="/../assets/img/landing-page/DSC01117-81.JPG" alt="Photo EAt'Z"></div>
  <div class="car-block"><img class="img-mockup" src="/../assets/img/landing-page/sunset-menu.png"></div>
</div>
