<div class="page_font">
  <div class="page_width_lp">
    <h2>{{ 'landing.cta-title' | translate }}</h2>
    <div class="arg_disp">
      <div class="arg">
        <i class="bi bi-check2-circle"></i>
        {{ 'landing.cta-arg1' | translate }}
      </div>
      <div class="arg">
        <i class="bi bi-check2-circle"></i>
        {{ 'landing.cta-arg2' | translate }}
      </div>
      <div class="arg">
        <i class="bi bi-check2-circle"></i>
        {{ 'landing.cta-arg3' | translate }}
      </div>
    </div>
    <button routerLink="/auth/rest-signup">{{ 'landing.cta-button' | translate }}</button>
  </div>
</div>
