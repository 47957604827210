<div class="page_font">
  <h2>{{ 'landing.menu-qr.features-title' | translate }}</h2>

  <div class="arg-disp">
    <div class="arg-box">
      <div class="arg-img">
        <img src="/../assets/img/landing-page/restaurant-unique-2-w505.jpg" alt="feature : restaurant unique" loading="lazy">
        <!--<video autoplay loop>

          <source src="/../assets/img/landing-page/restaurant-unique.mp4"
                  type="video/mp4">

          Sorry, your browser doesn't support embedded videos.
        </video>-->
      </div>
      <div class="arg">
        <div class="arg-left">
          <div class="arg-logo">
            <i class="bi bi-shop"></i>
          </div>
        </div>
        <div class="arg-right">
          <p class="arg-title">{{ 'landing.menu-qr.features-personnalisation' | translate }}</p>
          <p class="arg-description">{{ 'landing.menu-qr.features-personnalisation-p' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="arg-box">
      <div class="arg-img">
        <img src="/../assets/img/landing-page/menu-augmente-w505.jpg" alt="feature : augmented experience" loading="lazy">
      </div>
      <div class="arg">
        <div class="arg-left">
          <div class="arg-logo">
            <i class="bi bi-journal-richtext"></i>
          </div>
        </div>
        <div class="arg-right">
          <p class="arg-title">{{ 'landing.menu-qr.features-modification' | translate }}</p>
          <p class="arg-description">{{ 'landing.menu-qr.features-modification-p' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="arg-box">
      <div class="arg-img">
        <img src="/../assets/img/landing-page/translation-w505.jpg" alt="feature : translation" loading="lazy">
      </div>
      <div class="arg">
        <div class="arg-left">
          <div class="arg-logo">
            <i class="bi bi-translate"></i>
          </div>
        </div>
        <div class="arg-right">
          <p class="arg-title">{{ 'landing.menu-qr.features-traduction' | translate }}</p>
          <p class="arg-description">{{ 'landing.menu-qr.features-traduction-p' | translate }}</p>
          <p style="margin-top: 10px; font-style: italic; font-size: 14px">{{ 'landing.menu-qr.features-traduction-p-lang' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="arg-box">
      <div class="arg-img">
        <img src="/../assets/img/landing-page/carte-papier-2-w505.jpg" alt="feature : carte papier" loading="lazy">
      </div>
      <div class="arg">
        <div class="arg-left">
          <div class="arg-logo">
            <i class="bi bi-sun"></i>
          </div>
        </div>
        <div class="arg-right">
          <p class="arg-title">{{ 'landing.menu-qr.features-ecologique' | translate }}</p>
          <p class="arg-description">{{ 'landing.menu-qr.features-ecologique-p' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
