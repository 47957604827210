<div class="header-height">
  <div class="header-box">
    <h1>Boutique QR codes</h1>
    <p>Choisissez parmi une séléction de QR codes en bois et plastique recyclable produits en France.</p>
    <i class="bi bi-chevron-down"></i>
  </div>
</div>

<div class="page-font" *ngIf="!showSupportDetails">
  <h2>Nos supports</h2>
  <div class="object-disp">
    <div class="object" *ngFor="let object of Objects"> <!--(click)="openSupportDetails(object)"-->
      <div class="object-img-disp">
        <div class="object-img" style="background-image: url({{object.photo}});"></div>
      </div>
      <div class="object-description">
        <p class="object-description-title">{{object.name}}</p>
<!--        <p class="object-description-description">{{object.description}}</p>-->
        <p class="object-price">{{object.price}}€</p>
      </div>
    </div>
  </div>
</div>

<div class="page-font" *ngIf="showSupportDetails">
  <h2>{{selectedObject.name}}</h2>
</div>
