import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';

import { FooterComponent } from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {DigitalMenuPresentationComponent} from './digital-menu-presentation/digital-menu-presentation.component';
import {NewLandingPageComponent} from './new-landing-page/new-landing-page.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { FeaturesMultipleTreeComponent } from './landing-widgets/features-multiple-tree/features-multiple-tree.component';
import { FeatureSingleDigitalMenuComponent } from './landing-widgets/feature-single-digital-menu/feature-single-digital-menu.component';
import { FeatureSinglePaiementComponent } from './landing-widgets/feature-single-paiement/feature-single-paiement.component';
import { FeatureSingleMarketplaceComponent } from './landing-widgets/feature-single-marketplace/feature-single-marketplace.component';
import { FeaturesMultiplesOtherServicesComponent } from './landing-widgets/features-multiples-other-services/features-multiples-other-services.component';
import { CtaBeginTodayBigComponent } from './landing-widgets/cta-begin-today-big/cta-begin-today-big.component';
import { StatsExpClientComponent } from './landing-widgets/stats-exp-client/stats-exp-client.component';
import { Testimonial1Component } from './landing-widgets/testimonial1/testimonial1.component';
import { CtaCreateYourAccountComponent } from './landing-widgets/cta-create-your-account/cta-create-your-account.component';
import { OurRestaurantsComponent } from './digital-menu-widgets/our-restaurants/our-restaurants.component';
import { FeaturesMultipleDigitalMenuComponent } from './digital-menu-widgets/features-multiple-digital-menu/features-multiple-digital-menu.component';
import { MenuExamplesDigitalMenuComponent } from './digital-menu-widgets/menu-examples-digital-menu/menu-examples-digital-menu.component';
import { FeaturesExamplesDigitalMenuComponent } from './digital-menu-widgets/features-examples-digital-menu/features-examples-digital-menu.component';
import { HomeComponent } from './home.component';
import { ProjectPriceComponent } from './digital-menu-widgets/project-price/project-price.component';
import { LogosSectionComponent } from './landing-widgets/logos-section/logos-section.component';
import { PaiementPresentationComponent } from './paiement-presentation/paiement-presentation.component';
import { MarketplacePresentationComponent } from './marketplace-presentation/marketplace-presentation.component';
import { DigitalMenuPresentationCtaComponent } from './digital-menu-widgets/digital-menu-presentation-cta/digital-menu-presentation-cta.component';
import { DigitalMenuPresentationAdminSingleComponent } from './digital-menu-widgets/digital-menu-presentation-admin-single/digital-menu-presentation-admin-single.component';
import { FeatureSingleRoomServiceComponent } from './landing-widgets/feature-single-room-service/feature-single-room-service.component';
import { FeatureCarouselComponent } from './landing-widgets/feature-carousel/feature-carousel.component';
import { DigitalMenuVideoComponent } from './digital-menu-widgets/digital-menu-video/digital-menu-video.component';
import { DigitalMenuFaqComponent } from './digital-menu-widgets/digital-menu-faq/digital-menu-faq.component';
import { DigitalMenuStatsComponent } from './digital-menu-widgets/digital-menu-stats/digital-menu-stats.component';
import { DigitalMenuThemesComponent } from './digital-menu-widgets/digital-menu-themes/digital-menu-themes.component';
import { DigitalMenuAdminSlidershowComponent } from './digital-menu-widgets/digital-menu-admin-slidershow/digital-menu-admin-slidershow.component';
import { PricesComponent } from './prices/prices.component';
import { HomeMarketplaceComponent } from './home-marketplace/home-marketplace.component';
import {MarketplaceModule} from '../marketplace/marketplace.module';
import { FeaturesMultipleDigitalMenuBigComponent } from './digital-menu-widgets/features-multiple-digital-menu-big/features-multiple-digital-menu-big.component';
import { LandingStepsComponent } from './landing-widgets/landing-steps/landing-steps.component';
import { LegalsComponent } from './legals/legals.component';


@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    DigitalMenuPresentationComponent,
    NewLandingPageComponent,
    FeaturesMultipleTreeComponent,
    FeatureSingleDigitalMenuComponent,
    FeatureSinglePaiementComponent,
    FeatureSingleMarketplaceComponent,
    FeaturesMultiplesOtherServicesComponent,
    CtaBeginTodayBigComponent,
    StatsExpClientComponent,
    Testimonial1Component,
    CtaCreateYourAccountComponent,
    OurRestaurantsComponent,
    FeaturesMultipleDigitalMenuComponent,
    MenuExamplesDigitalMenuComponent,
    FeaturesExamplesDigitalMenuComponent,
    HomeComponent,
    ProjectPriceComponent,
    LogosSectionComponent,
    PaiementPresentationComponent,
    MarketplacePresentationComponent,
    DigitalMenuPresentationCtaComponent,
    DigitalMenuPresentationAdminSingleComponent,
    FeatureSingleRoomServiceComponent,
    FeatureCarouselComponent,
    DigitalMenuVideoComponent,
    DigitalMenuFaqComponent,
    DigitalMenuStatsComponent,
    DigitalMenuThemesComponent,
    DigitalMenuAdminSlidershowComponent,
    PricesComponent,
    HomeMarketplaceComponent,
    FeaturesMultipleDigitalMenuBigComponent,
    LandingStepsComponent,
    LegalsComponent,
  ],
    imports: [
        CommonModule,
        HomeRoutingModule,
        ReactiveFormsModule,
        TranslateModule,
        MarketplaceModule,
    ],
  exports: [
    FooterComponent,
    HeaderComponent

  ]
})
export class HomeModule { }
