<div class="features_disp">
  <div class="feature-img-box">
    <img src="/../assets/img/landing-page/menu-iphone-x.png" alt="Menu EAT'Z">
  </div>
  <div class="feature-description">
    <h2>A marketplace to sell your products</h2>
    <p>{{ 'landing.marketplace-p' | translate }}</p>
    <div class="a-disp">
      <a routerLink="/presentation/marketplace">{{ 'landing.know-more' | translate }} <i class="bi bi-arrow-right"></i></a>
      <a routerLink="/auth/rest-signup" class="a-button-cma">{{ 'landing.menu-qr.create-account' | translate }} <i class="bi bi-arrow-right"></i></a>
    </div>
  </div>
</div>


<!--
<div class="page_font">
  <div class="page_width_lp">
    <div class="feature-description">
      <h2>A marketplace to sell your products</h2>&lt;!&ndash;{{ 'landing.marketplace-title' | translate }}&ndash;&gt;
      <div class="feature-description-text">
        <p>{{ 'landing.marketplace-p' | translate }}</p>
        <div class="a-disp">
          <a routerLink="/presentation/marketplace">{{ 'landing.know-more' | translate }} <i class="bi bi-arrow-right"></i></a>
          <a routerLink="/auth/rest-signup">{{ 'landing.menu-qr.create-account' | translate }} <i class="bi bi-arrow-right"></i></a>
        </div>
      </div>
    </div>
    <div class="feature-img-box"></div>
  </div>
</div>
-->
