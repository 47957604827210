<div class="features_disp">
  <div class="feature-img-box">
    <img src="/../assets/img/landing-page/paiement-item-1.png" alt="paiement EAT'Z" id="item1">
    <img src="/../assets/img/landing-page/paiement-item-2.png" alt="paiement illustration EAT'Z" id="item2">
  </div>
  <div class="feature-description">
    <p class="h2-info">{{ 'landing.menu-qr.paiement-compatible' | translate }}</p>
    <h2>{{ 'landing.menu-qr.paiement-title' | translate }}</h2>
    <ul>
      <li><b>Paiement de l'addition</b> à table</li>
      <li>Plateforme de <b>vente à emporter</b> à votre image</li>
      <li>Augmentation moyenne de <b>30% des pourboires</b></li>
      <li>Augmentation du turnover des tables</li>
      <li>Statistiques détaillées</li>
    </ul><!--{{ 'landing.menu-qr.paiement-p' | translate }}-->
    <div class="a-disp">
      <a routerLink="/presentation/paiement">{{ 'landing.know-more-paiement' | translate }}</a>
      <a routerLink="/auth/rest-signup" class="a-button-cma">{{ 'landing.menu-qr.create-account' | translate }}</a>
    </div>
  </div>
</div>
