<div class="avantages">
  <div class="page_width_lp">
    <h2>{{ 'landing.advantages-title' | translate }}</h2> <!--{{ 'landing.advantages' | translate }}-->
    <div class="avantages_disp">
      <div class="avantages_list">
        <div class="avantages_list_disp">
          <div class="avantages_list_box" (click)="avFalse(); av1 = true;" [ngClass]="{'avantages_list_box_selected': av1}">
            <div class="avantages_list_box_logo_box">
              <i class="bi bi-translate"></i>
            </div>
            <div class="avantages_list_box_description_box">
              <h3>{{ 'landing.advantages-trad' | translate }}</h3>
              <p>{{ 'landing.advantages-trad-t' | translate }}</p>
            </div>
            <div class="avantages_progress_bar" [ngStyle]="{'width': av1 ? '100%' : '0', 'opacity': av1 ? '1' : '0'}"></div>
          </div>
          <div class="avantages_list_box" (click)="avFalse(); av2 = true;"  [ngClass]="{'avantages_list_box_selected': av2}">
            <div class="avantages_list_box_logo_box">
              <i class="bi bi-brush-fill"></i>
            </div>
            <div class="avantages_list_box_description_box">
              <h3>{{ 'landing.advantages-mod' | translate }}</h3>
              <p>{{ 'landing.advantages-mod-t' | translate }}</p>
            </div>
            <div class="avantages_progress_bar" [ngStyle]="{'width': av2 ? '100%' : '0', 'opacity': av2 ? '1' : '0'}"></div>
          </div>
          <div class="avantages_list_box" (click)="avFalse(); av3 = true;"  [ngClass]="{'avantages_list_box_selected': av3}">
            <div class="avantages_list_box_logo_box">
              <i class="bi bi-image"></i>
            </div>
            <div class="avantages_list_box_description_box">
              <h3>{{ 'landing.advantages-photo' | translate }}</h3>
              <p>{{ 'landing.advantages-photo-t' | translate }}</p>
            </div>
            <div class="avantages_progress_bar" [ngStyle]="{'width': av3 ? '100%' : '0', 'opacity': av3 ? '1' : '0'}"></div>
          </div>
          <div class="avantages_list_box" (click)="avFalse(); av4 = true;"  [ngClass]="{'avantages_list_box_selected': av4}">
            <div class="avantages_list_box_logo_box">
              <i class="bi bi-phone-fill"></i>
            </div>
            <div class="avantages_list_box_description_box">
              <h3>{{ 'landing.advantages-mobile' | translate }}</h3>
              <p>{{ 'landing.advantages-mobile-t' | translate }}</p>
            </div>
            <div class="avantages_progress_bar" [ngStyle]="{'width': av4 ? '100%' : '0', 'opacity': av4 ? '1' : '0'}"></div>
          </div>
        </div>
      </div>
      <img src="assets/img/avantage_1.jpg" alt="Avantage 1" *ngIf="av1">
      <img src="assets/img/avantage_2.jpg" alt="Avantage 2" *ngIf="av2">
      <img src="assets/img/avantage_3.jpg" alt="Avantage 3" *ngIf="av3">
      <img src="assets/img/avantage_4.jpg" alt="Avantage 4" *ngIf="av4">
    </div>
  </div>
</div>
