import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import * as firebase from 'firebase';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  UserCache: any = [{
    name: null,
    roles: {admin: false},
    id: null,
    email: null,
    isAuth: false
  }];
  userCacheSubject = new Subject<any[]>();

  constructor(private readonly afs: AngularFirestore,
              private router: Router,
              private _location: Location,) { }

  emitUserCacheSubject() {
    this.userCacheSubject.next(this.UserCache.slice());
  }

  updateUserCache(UserCache) {
    if (UserCache) {
      this.UserCache[0].name = UserCache.displayName;
      this.UserCache[0].id = UserCache.uid;
      this.UserCache[0].email = UserCache.email;
      this.UserCache[0].isAuth = true;

      // Get in the cache file on firestore the roles --> allow acces to certain parts of the site
      firebase.default.firestore().doc('users/' + UserCache.uid)
        .get()
        .then(
          (doc) => {
            if (doc.data().roles != undefined) {
              this.UserCache[0].roles = doc.data().roles;
            }
          }).catch(function(error) {
            //No user cache defined on firebase
      })
    } else {
      this.UserCache = [{
        name: null,
        roles: {admin: false},
        id: null,
        email: null,
        isAuth: false
      }];
    }

    this.emitUserCacheSubject()
  }

  //determine if user has matching role
  /*private checkAuthorization(UserCache, allowedRoles): boolean {
    if (!UserCache) return false
    for (const role of allowedRoles) {
      if (UserCache[0].roles[role]) {
        return true
      }
    }
    return false
  }

  isAdmin(UserCache): boolean {
    const allowed = ['admin']
    return this.checkAuthorization(UserCache, allowed)
  }*/

  createNewUser(email: string, password: string) {
    return new Promise(
      (resolve, reject) => {
        firebase.default.auth().createUserWithEmailAndPassword(email, password).then(
          (credential) => {
            this.updateUserCache(credential.user)
            resolve(true);
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  createNewUserViaGoogle() {
    return new Promise(
      (resolve, reject) => {
        firebase.default.auth().signInWithPopup(new firebase.default.auth.GoogleAuthProvider()).then(
          (credential) => {
            this.updateUserCache(credential.user)
            resolve(true);
            this._location.back();
          },
          (error) => {
            reject(error);
          }
        )
      })
  }

  SignInUser(email: string, password: string) {
    return new Promise(
      (resolve, reject) => {
        firebase.default.auth().signInWithEmailAndPassword(email, password).then(
          (credential) => {
            this.updateUserCache(credential.user)
            resolve(true);
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  UpdateUser(name: string, photoUrl: string){
    var user = firebase.default.auth().currentUser;
    user.updateProfile({
      displayName: name,
      photoURL: photoUrl,
    }).then(function() {
      // Update successful.
    }).catch(function(error) {
      // An error happened.
    });
  }

  UpdateUserName(name: string){
    var user = firebase.default.auth().currentUser;
    user.updateProfile({
      displayName: name,
    }).then(function() {
      // Update successful.
    }).catch(function(error) {
      // An error happened.
    });
  }

  updateProfilePhoto(file: File) {
    return new Promise(
      (resolve, reject) => {
        const almostUniqueFileName = Date.now().toString();
        const upload = firebase.default.storage().ref()
          .child('images/'+ 'dan' + '/'+ almostUniqueFileName + file.name)
          .put(file);
        upload.on(firebase.default.storage.TaskEvent.STATE_CHANGED,
          () => {
            console.log('Chargement...')
          },
          (error) => {
            console.log('Erreur de chargement: ' + error)
            reject();
          },
          () => {
            resolve(upload.snapshot.ref.getDownloadURL());
          }
        );
      }
    );
  }

  RouteUser(id: number) {
  }

  SignOutUser() {
    firebase.default.auth().signOut();
    this.router.navigate(['delivery'])
  }
}
