import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-testimonial1',
  templateUrl: './testimonial1.component.html',
  styleUrls: ['./testimonial1.component.scss']
})
export class Testimonial1Component implements OnInit {
  paddingTestimonialDisp: number;
  numberOfClickOnNext = 1;

  constructor() { }

  ngOnInit(): void {
    this.onResize();
  }

  onResize() {
    if (window.innerWidth > 800) {
      this.paddingTestimonialDisp = window.innerWidth - 1350;
    } else {
      this.paddingTestimonialDisp = 15;
    }
  }

  goNext() {
    if (this.numberOfClickOnNext < 3) {
      this.paddingTestimonialDisp = this.paddingTestimonialDisp - 500;
      this.numberOfClickOnNext ++
    }
  }

  goBack() {
    if (this.numberOfClickOnNext > 1) {
      this.paddingTestimonialDisp = this.paddingTestimonialDisp + 500;
      this.numberOfClickOnNext --
    }
  }
}
