<h2>Comment ça marche :</h2>
<div class="steps-disp">
  <div class="step">
    <p class="step-count">1</p>
    <i class="bi bi-plus-circle-fill"></i>
    <p class="step-title">Création</p>
    <p class="step-description">
      Créez votre menu <b>en quelques clics</b> depuis notre site internet. <b>Ajoutez, Modifiez et Supprimez</b> des plats et catégories facilement.
    </p>
  </div>
  <div class="step">
    <p class="step-count">2</p>
    <i class="bi bi-brush-fill"></i>
    <p class="step-title">Personnalisation</p>
    <p class="step-description">
      Personnalisez votre menu <b>à votre image</b> avec une large gamme d'options (logo, couleurs, typographie, widgets, etc.).
    </p>
  </div>
  <div class="step">
    <p class="step-count">3</p>
    <i class="bi bi-qr-code-scan"></i>
    <p class="step-title">Diffusion</p>
    <p class="step-description">
      Partagez le menu <b>auprès de vos clients</b>, diffusez le aussi sur vos <b>réseaux sociaux</b>.
    </p>
  </div>
</div>
