<div style="background-color: #fff;">
  <h2>{{ 'landing.features-multiple-title' | translate }}</h2>
  <div class="features-disp">
    <div class="feature">
      <i class="bi bi-phone-fill first-logo"></i>
      <h4>{{ 'landing.features-multiple-1' | translate }}</h4>
      <p>{{ 'landing.features-multiple-1-p' | translate }}</p>
      <a routerLink="/presentation/menu-qr">{{ 'landing.features-multiple-cta' | translate }} <i class="bi bi-arrow-right"></i></a>
    </div>
    <div class="feature">
      <i class="bi bi-credit-card-2-front-fill first-logo"></i>
      <h4>{{ 'landing.features-multiple-2' | translate }}</h4>
      <p>{{ 'landing.features-multiple-2-p' | translate }}</p>
      <a routerLink="/presentation/paiement">{{ 'landing.features-multiple-cta' | translate }} <i class="bi bi-arrow-right"></i></a>
    </div>
    <div class="feature">
      <i class="bi bi-shop-window first-logo"></i>
      <h4>{{ 'landing.features-multiple-3' | translate }}</h4>
      <p>{{ 'landing.features-multiple-3-p' | translate }}</p>
      <a routerLink="/presentation/marketplace">{{ 'landing.features-multiple-cta' | translate }} <i class="bi bi-arrow-right"></i></a>
    </div>
  </div>

</div>
