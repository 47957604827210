<app-header></app-header>
<div class="page_font">
  <div class="digital_menu_hero">

    <h1>{{ 'landing.paiement.title' | translate }}</h1>

    <button class="hero_button">{{ 'landing.available-soon' | translate }}</button>

    <div class="hero_green_circle"></div>
    <img class="hero-menu-img" src="/../assets/img/landing-page/menu-iphone-x.png" alt="Menu EAT'Z">
  </div>
  <div class="page_width_lp" style="padding: 30px;">
    <h2 style="border-bottom: 2px solid var(--eatz-vert-fonce); width: fit-content; margin-left: auto; margin-right: auto;">{{ 'landing.menu-qr.see-next' | translate }}</h2>
    <app-feature-single-digital-menu></app-feature-single-digital-menu>
  </div>
  <app-cta-create-your-account></app-cta-create-your-account>
</div>

<app-footer></app-footer>
