<div class="page_font">
  <h2>Avantages</h2>
  <div class="arg-disp">
    <div class="arg">
      <div class="arg-description">
        <p class="arg-title">{{ 'landing.menu-qr.features-personnalisation' | translate }}</p>
        <p class="arg-text">{{ 'landing.menu-qr.features-personnalisation-p' | translate }}</p>
      </div>
      <div class="arg-img">
        <img src="/../assets/img/landing-page/fonctionnalite1-2.jpg" alt="feature : augmented experience" loading="lazy">
      </div>
    </div>
    <div class="arg">
      <div class="arg-description">
        <p class="arg-title">{{ 'landing.menu-qr.features-modification' | translate }}</p>
        <p class="arg-text">{{ 'landing.menu-qr.features-modification-p' | translate }}</p>
      </div>
      <div class="arg-img">
        <img src="/../assets/img/landing-page/fonctionnalite2.jpg" alt="feature : augmented experience" loading="lazy">
      </div>
    </div>
    <div class="arg">
      <div class="arg-description">
        <p class="arg-title">{{ 'landing.menu-qr.features-traduction' | translate }}</p>
        <p class="arg-text">{{ 'landing.menu-qr.features-traduction-p' | translate }}</p>
        <p style="margin-top: 10px; font-style: italic; font-size: 14px">{{ 'landing.menu-qr.features-traduction-p-lang' | translate }}</p>
      </div>
      <div class="arg-img">
        <img src="/../assets/img/landing-page/fonctionnalite3.jpg" alt="feature : translation" loading="lazy">
      </div>
    </div>
    <div class="arg">
      <div class="arg-description">
        <p class="arg-title">{{ 'landing.menu-qr.features-ecologique' | translate }}</p>
        <p class="arg-text">{{ 'landing.menu-qr.features-ecologique-p' | translate }}</p>
      </div>
      <div class="arg-img">
        <img src="/../assets/img/landing-page/fonctionnalite5.jpg" alt="feature : carte papier" loading="lazy">
      </div>
    </div>
  </div>
</div>
