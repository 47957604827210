import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {Location} from '@angular/common';

@Component({
  selector: 'app-new-landing-page',
  templateUrl: './new-landing-page.component.html',
  styleUrls: ['./new-landing-page.component.scss']
})
export class NewLandingPageComponent implements OnInit {

  ContactUsForm: FormGroup;
  messageSent: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private firestore: AngularFirestore,
              private _location: Location) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
  }

  backClicked() {
    this._location.back();
  }
}
