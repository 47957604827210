import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit {

  showSupportDetails = false;
  selectedObject: any;

  Objects = [
    {name: 'Support en bois - Gravure laser', photo: '../../../assets/img/marketplace/qr-simple.jpg', description: '', price: 6, currency : 'eur'},
    {name: 'Mini stand', photo: '../../../assets/img/marketplace/mini-blanc.jpg', description: '', price: 7.5, currency : 'eur'},
    {name: 'Stand iMac fin', photo: '../../../assets/img/marketplace/stand-imac-fin.jpg', description: '', price: 9.5, currency : 'eur'},
    {name: 'Stand Bar', photo: '../../../assets/img/marketplace/pinguin.jpg', description: '', price: 14.5, currency : 'eur'},
  ]

  constructor() { }

  ngOnInit(): void {
  }

  openSupportDetails(object) {
    this.showSupportDetails = true;
    this.selectedObject = object;
  }
}
