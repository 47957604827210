import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-menu-video',
  templateUrl: './digital-menu-video.component.html',
  styleUrls: ['./digital-menu-video.component.scss']
})
export class DigitalMenuVideoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
