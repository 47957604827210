import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-feature-carousel',
  templateUrl: './feature-carousel.component.html',
  styleUrls: ['./feature-carousel.component.scss']
})
export class FeatureCarouselComponent implements OnInit {
  number: number;
  number1: number;
  scale: number;

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.number = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) / 20 - 100;
    this.number1 = -(window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) / 10;
    this.scale = ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) / 10000) + 1;
    //this.header = number >= 50;
  }

}
