<div class="features_disp">
  <div class="feature-img-box" [ngStyle]="{'max-width': squareH+'px','max-height': squareH+'px'}">
    <img src="/../assets/img/landing-page/service.jpg" alt="Service EAT'Z">
  </div>
  <div class="feature-description">
    <!--<h2>Transformez votre façon de servir vos clients</h2>-->
    <h2>Passez plus de temps auprès de vos clients</h2>

    <ul>
      <li>Une application discrète sur votre montre</li>
      <li>Soyez notifié quand un client à besoin de vous</li>
      <li>Soyez notifié quand un plat est prêt</li>
    </ul>
    <div class="a-disp">
      <a routerLink="/presentation/marketplace">{{ 'landing.know-more' | translate }} <i class="bi bi-arrow-right"></i></a>
      <a routerLink="/auth/rest-signup" class="a-button-cma">{{ 'landing.menu-qr.create-account' | translate }} <i class="bi bi-arrow-right"></i></a>
    </div>
  </div>
</div>
