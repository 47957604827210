<div class="page_font">
  <h2>{{ 'landing.other-services-title' | translate }}</h2>
<!--
  <p>Répondez aux attentes des consommateurs en réinventant votre parcours client grâce à notre service digital</p>&lt;!&ndash;{{ 'landing.other-services-p' | translate }}&ndash;&gt;
-->

  <div class="features-disp">
    <div class="feature">
      <i class="bi bi-person-lines-fill first-logo"></i>
      <h4>{{ 'landing.other-services-1' | translate }}</h4>
      <!--<p class="feature-price">{{ 'landing.other-services-1-p1' | translate }}</p>-->
      <p class="feature-description">{{ 'landing.other-services-1-p2' | translate }}</p>
    </div>
    <div class="feature">
      <i class="bi bi-qr-code first-logo"></i>
      <h4>{{ 'landing.other-services-2' | translate }}</h4>
      <!--<p class="feature-price">{{ 'landing.other-services-2-p1' | translate }}</p>-->
      <p class="feature-description">{{ 'landing.other-services-2-p2' | translate }}</p>
    </div>
  </div>
</div>
