<link rel="stylesheet" href="digital-menu-presentation.component.scss">
<app-header></app-header>

<div class="page_font">
  <div class="digital_menu_hero">

    <h1>{{ 'landing.menu-qr.title' | translate }}</h1>

    <button class="hero_button" routerLink="/auth/rest-signup">{{ 'landing.menu-qr.create-account' | translate }}</button>

    <div class="hero_green_circle"></div>
    <img class="hero-menu-img" src="/../assets/img/landing-page/iphone-semi-lou-ginestre.png" alt="Menu EAT'Z">
  </div>

  <div class="page_width_lp">
    <app-our-restaurants></app-our-restaurants>
    <app-digital-menu-video></app-digital-menu-video>
    <app-features-multiple-digital-menu></app-features-multiple-digital-menu>
    <app-digital-menu-presentation-admin-single></app-digital-menu-presentation-admin-single>
    <!--<app-digital-menu-admin-slidershow></app-digital-menu-admin-slidershow>-->
    <app-digital-menu-stats></app-digital-menu-stats>
    <app-menu-examples-digital-menu></app-menu-examples-digital-menu>
<!--    <app-digital-menu-themes></app-digital-menu-themes>-->
    <!--<app-features-examples-digital-menu></app-features-examples-digital-menu>-->
<!--
    <app-digital-menu-presentation-cta></app-digital-menu-presentation-cta>
-->
    <app-project-price></app-project-price>
    <app-digital-menu-faq></app-digital-menu-faq>
  </div>

  <app-cta-create-your-account></app-cta-create-your-account>
  <div class="page_width_lp" style="padding: 30px;">
    <h2 style="border-bottom: 2px solid var(--eatz-vert-fonce); width: fit-content; margin-left: auto; margin-right: auto;">{{ 'landing.menu-qr.see-next' | translate }}</h2>
    <app-feature-single-paiement></app-feature-single-paiement>
  </div>
</div>

<app-footer></app-footer>



<!--
<div class="container">
  <section class="child" id="title_section">
    <h1>Menu numérique</h1>
    <h2><b>Traduction automatique</b>, interface convivial <b>à votre image</b>, <b>administration</b> sur-mesure, chargement <b>instantané</b>, et ce n'est que le début !</h2>
    <img src="/../../../assets/img/presentation_title_eatz_menu_qr.png" alt="presentation eatz menu qr">
    <button routerLink="/contact-us">Prendre Contact</button>

    <div class="section_discover">
      <h3>Découvrir</h3>
      <i class="bi bi-chevron-down"></i>
    </div>
  </section>
  <section class="child" id="interface_section">
    <h3>Découvrir l'interface</h3>
    <div class="slider">
      <section class="side_container_child" id="interface_section_2">
        <img src="/../../../assets/img/eatz_digital_menu_arg2.jpg" alt="Traduction EAT'Z">
        <p class="slider_EATZ_CEST">EAT’Z c’est :</p>
        <p class="slider_EATZ_CEST_p1">Votre menu traduit automatiquement en 6 langues</p>
        <p class="slider_EATZ_CEST_p2">Traduction en Anglais, Français, Espagnol, Mandarin, Russe et Hebreu</p>
        <i class="bi bi-chevron-right"></i>
      </section>
      <section class="side_container_child" id="interface_section_1">
        <img src="/../../../assets/img/eatz_digital_menu_arg1.jpg" alt="Interface d'administration EAT'Z">
        <p class="slider_EATZ_CEST">EAT’Z c’est :</p>
        <p class="slider_EATZ_CEST_p1">Une plateforme d’administration simple et rapide</p>
        <p class="slider_EATZ_CEST_p2">Ajoutez, modifiez et supprimez un plat en un clic</p>
        <i class="bi bi-chevron-right"></i>
      </section>
      <section class="side_container_child" id="interface_section_3">
        <img src="/../../../assets/img/eatz_digital_menu_arg3.jpg" alt="Interface Carte EAT'Z">
        <p class="slider_EATZ_CEST">EAT’Z c’est :</p>
        <p class="slider_EATZ_CEST_p1">Une carte à l’image de votre restaurant</p>
        <p class="slider_EATZ_CEST_p2">Personnalisable, avec vos plats du jour et des promotions programmables.</p>
        <i class="bi bi-chevron-right"></i>
      </section>
      <section class="side_container_child" id="interface_section_4">
        <img src="/../../../assets/img/eatz_digital_menu_arg4.jpg" alt="Statistiques EAT'Z">
        <p class="slider_EATZ_CEST">EAT’Z c’est :</p>
        <p class="slider_EATZ_CEST_p1">Un accès à vos statistiques depuis l'administration</p>
        <p class="slider_EATZ_CEST_p2">Nombre de clients, volume de vente, plats favoris, etc.</p>
        <i class="bi bi-chevron-right"></i>
      </section>
      <section class="side_container_child" id="interface_section_5">
        <img src="/../../../assets/img/eatz_digital_menu_arg5.jpg" alt="Interface catégories EAT'Z">
        <p class="slider_EATZ_CEST">EAT’Z c’est :</p>
        <p class="slider_EATZ_CEST_p1">Une carte pensée pour un usage sur téléphone.</p>
        <p class="slider_EATZ_CEST_p2">La carte est organisée par des catégories accompagnées de belles photos.</p>
        <i class="bi bi-chevron-right"></i>
      </section>
    </div>
  </section>
  <section class="child" id="price_section">
    <h3>Tarifs</h3>
    <div class="price_bubble_disp">
      <img class="price_bubble" src="/../../../assets/img/qr_code_table_usecase.jpg" alt="Démonstration du produit">
      <div class="price_bubble">
        <div class="price_bubble_name">Basic</div>
        <p class="price_bubble_price" style="padding-bottom: 15px;">Gratuit</p>
        <ul>
          <li>Création de la carte</li>
          <li>Modifications illimitées</li>
          <li>Traduction en 8 langues</li>
          <li>Possibilité d'afficher sa carte sous forme de QR code sur les tables</li>
          <li>Diffusez un lien de votre carte  à vos clients (réseaux sociaux, message, mail, etc.)</li>
          <li>Sections de "Menu du jour", lien vers les réseaux sociaux, etc.</li>
          <li style="color: var(&#45;&#45;eatz-red)">Jusqu'à 20 commandes en Click & Collect **</li>
        </ul>
        <p class="price_bubble_asterisque">*sans engagement</p>
        <p class="price_bubble_asterisque">**0.25€ + 2.9% par commande</p>
      </div>
      <div class="price_bubble">
        <div class="price_bubble_name">Premium</div>
        <p class="price_bubble_periode">Mensuel</p>
        <p class="price_bubble_price">59€</p>
        <p class="price_bubble_ht">HT / mois</p>
        <p class="price_bubble_argument">Toutes les options du forfait basic</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
          <path id="Union_3" data-name="Union 3" d="M-898-978v-8h-8a3,3,0,0,1-3-3,3,3,0,0,1,3-3h8v-8a3,3,0,0,1,3-3,3,3,0,0,1,3,3v8h8a3,3,0,0,1,3,3,3,3,0,0,1-3,3h-8v8a3,3,0,0,1-3,3A3,3,0,0,1-898-978Z" transform="translate(909 1003)" fill="#1f3eb4"/>
        </svg>
        <ul>
          <li>Accès à la plateforme de Click & Collect et de livraison</li>
          <li>Accès aux statistiques de la journée</li>
          <li>50 QR codes à l'image de votre restaurant offerts</li>
          <li style="color: var(&#45;&#45;eatz-red)">Jusqu'à 150 commandes en Click & Collect**</li>
        </ul>
        <p class="price_bubble_asterisque">*sans engagement</p>
        <p class="price_bubble_asterisque">**0.25€ + 2.9% par commande</p>
      </div>
      <div class="price_bubble">
        <div class="price_bubble_name">Premium +</div>
        <p class="price_bubble_periode">Mensuel</p>
        <p class="price_bubble_price">149€</p>
        <p class="price_bubble_ht">HT / mois</p>
        <p class="price_bubble_argument">Toutes les options du forfait premium</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
          <path id="Union_3" data-name="Union 3" d="M-898-978v-8h-8a3,3,0,0,1-3-3,3,3,0,0,1,3-3h8v-8a3,3,0,0,1,3-3,3,3,0,0,1,3,3v8h8a3,3,0,0,1,3,3,3,3,0,0,1-3,3h-8v8a3,3,0,0,1-3,3A3,3,0,0,1-898-978Z" transform="translate(909 1003)" fill="#1f3eb4"/>
        </svg>
        <ul>
          <li>Passez en tête de liste sur la plateforme de Click & Collect - Livraison</li>
          &lt;!&ndash;<li>Photographe sur demande (1 fois tout les 4 mois)</li>&ndash;&gt;
          <li>Assistance personnalisée (création, ajout, modification de la carte numérique)</li>
          <li style="color: var(&#45;&#45;eatz-red)">Commandes illimitées sur la plateforme de Click & Collect**</li>
        </ul>
        <p class="price_bubble_asterisque">*sans engagement</p>
        <p class="price_bubble_asterisque">**0.25€ + 2.9% par commande</p>
      </div>
    </div>
  </section>
  <section id="trust_section">
    <p class="p3">Vous êtes un groupe et vous possédez
      plusieurs établissements ?</p>
    <p class="p4">Contactez-nous, pour une offre sur mesure
      et adaptée à chacun de vos restaurants !</p>
    <button routerLink="/contact-us">Prendre Contact</button>
    <span class="line_span"></span>
    <p class="trust_section_title">Ils nous font confiance</p>
    <div class="trust_section_logos_disp">
      <div class="trust_section_logo" routerLink="/menu/CgjBwPzpbrA2LauasvHF">
        <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2FCgjBwPzpbrA2LauasvHF%2Fid_card%2Flogo?alt=media&token=0c2c45d8-4b82-46bc-ade0-6b37325e7654" alt="logo Florence Khan">
      </div>
      <div class="trust_section_logo" routerLink="/menu/WoT4ayoGM1iYZQ2WJoWn">
        <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2FWoT4ayoGM1iYZQ2WJoWn%2Fid_card%2Flogo?alt=media&token=32bdf443-28ae-4fee-bae5-37565677a526" alt="logo Club Player">
      </div>
      <div class="trust_section_logo" routerLink="/menu/XQCKkUFUlex2s3YFsPsT">
        <img src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2FXQCKkUFUlex2s3YFsPsT%2Fid_card%2Flogo?alt=media&token=de738b3a-6cd2-4364-877c-4cb53f98d052" alt="logo Causette & Bergamotte">
      </div>
      <div loading="lazy" class="trust_section_logo" routerLink="/menu/nJIBrGLuf0gsbM5ls48E">
        <img src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2FnJIBrGLuf0gsbM5ls48E%2Fid_card%2Flogo?alt=media&token=7a5bb6ef-63bc-4ae1-bdba-e9405aa2497c" alt="logo Sunset">
      </div>
      <div class="trust_section_logo" routerLink="/menu/pitchoun_cabane">
        <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2Fpitchoun_cabane%2Fid_card%2Flogo?alt=media&token=b0e1e81d-00ae-46db-b8fe-531215b54790" alt="logo Pitchoun Cabane">
      </div>
      <div class="trust_section_logo" routerLink="/menu/CgjBwPzpbrA2LauasvHF">
        <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2FCgjBwPzpbrA2LauasvHF%2Fid_card%2Flogo?alt=media&token=0c2c45d8-4b82-46bc-ade0-6b37325e7654" alt="logo Florence Khan">
      </div>
      <div class="trust_section_logo" routerLink="/menu/WoT4ayoGM1iYZQ2WJoWn">
        <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2FWoT4ayoGM1iYZQ2WJoWn%2Fid_card%2Flogo?alt=media&token=32bdf443-28ae-4fee-bae5-37565677a526" alt="logo Club Player">
      </div>
      <div class="trust_section_logo" routerLink="/menu/XQCKkUFUlex2s3YFsPsT">
        <img src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2FXQCKkUFUlex2s3YFsPsT%2Fid_card%2Flogo?alt=media&token=de738b3a-6cd2-4364-877c-4cb53f98d052" alt="logo Causette & Bergamotte">
      </div>
      <div loading="lazy" class="trust_section_logo" routerLink="/menu/nJIBrGLuf0gsbM5ls48E">
        <img src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2FnJIBrGLuf0gsbM5ls48E%2Fid_card%2Flogo?alt=media&token=7a5bb6ef-63bc-4ae1-bdba-e9405aa2497c" alt="logo Sunset">
      </div>
      <div class="trust_section_logo" routerLink="/menu/pitchoun_cabane">
        <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2Fpitchoun_cabane%2Fid_card%2Flogo?alt=media&token=b0e1e81d-00ae-46db-b8fe-531215b54790" alt="logo Pitchoun Cabane">
      </div>
      <div class="trust_section_logo" routerLink="/menu/CgjBwPzpbrA2LauasvHF">
        <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2FCgjBwPzpbrA2LauasvHF%2Fid_card%2Flogo?alt=media&token=0c2c45d8-4b82-46bc-ade0-6b37325e7654" alt="logo Florence Khan">
      </div>
      <div class="trust_section_logo" routerLink="/menu/WoT4ayoGM1iYZQ2WJoWn">
        <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2FWoT4ayoGM1iYZQ2WJoWn%2Fid_card%2Flogo?alt=media&token=32bdf443-28ae-4fee-bae5-37565677a526" alt="logo Club Player">
      </div>
      <div class="trust_section_logo" routerLink="/menu/XQCKkUFUlex2s3YFsPsT">
        <img src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2FXQCKkUFUlex2s3YFsPsT%2Fid_card%2Flogo?alt=media&token=de738b3a-6cd2-4364-877c-4cb53f98d052" alt="logo Causette & Bergamotte">
      </div>
      <div loading="lazy" class="trust_section_logo" routerLink="/menu/nJIBrGLuf0gsbM5ls48E">
        <img src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2FnJIBrGLuf0gsbM5ls48E%2Fid_card%2Flogo?alt=media&token=7a5bb6ef-63bc-4ae1-bdba-e9405aa2497c" alt="logo Sunset">
      </div>
      <div class="trust_section_logo" routerLink="/menu/pitchoun_cabane">
        <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/eatz-f56f4.appspot.com/o/images%2Frestaurants%2Fpitchoun_cabane%2Fid_card%2Flogo?alt=media&token=b0e1e81d-00ae-46db-b8fe-531215b54790" alt="logo Pitchoun Cabane">
      </div>
    </div>
  </section>
</div>-->
