import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-restaurants',
  templateUrl: './our-restaurants.component.html',
  styleUrls: ['./our-restaurants.component.scss']
})
export class OurRestaurantsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
