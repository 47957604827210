<div class="page_font">
  <div class="page_width_lp">
    <h2>{{ 'landing.our-clients-speak-title' | translate }}</h2>
    <p>{{ 'landing.our-clients-speak-p' | translate }}</p>
  </div>

  <button class="testimonial-button btn-right" (click)="goNext()"><i class="bi bi-arrow-right"></i></button>
  <button class="testimonial-button btn-left" (click)="goBack()"><i class="bi bi-arrow-left"></i></button>

  <div class="testimonials_disp" [ngStyle]="{'margin-left': paddingTestimonialDisp+'px'}">
    <div class="testimonial">
      <div class="testimonial_bubble">
        <p class="testimonial_title">Mes clients adorent</p>
        <p class="testimonial_text">Mes clients peuvent enfin voir les photos des plats avant de passer commande. De plus, j'aime vraiment le système de modification instantanée qui nous permet de mettre notre carte à jour régulièrement.</p>

        <div class="star-disp">
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
        </div>
        <div class="testimonial_bubble_bottom"></div>
      </div>
      <div class="profil_bubble" style="background-image: url(assets/img/landing-page/users/david.jpg);">
      </div>
      <p class="testimonial_person_name">David Lachkar</p>
      <p class="testimonial_person_description">Patron du restaurant "Au vieux four"</p>
    </div>
    <div class="testimonial">
      <div class="testimonial_bubble">
        <p class="testimonial_title">Ce que je cherchais</p>
        <p class="testimonial_text">Concept novateur et intelligent pour améliorer votre expérience client au restaurant. Facile d'utilisation, Eat'z propose de nombreuses fonctionnalités agréables tant pour le client du restaurant que pour le restaurateur.</p>
        <div class="star-disp">
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
        </div>
        <div class="testimonial_bubble_bottom"></div>
      </div>
      <div class="profil_bubble" style="background-image: url(assets/img/landing-page/users/person2.jpeg);">
      </div>
      <p class="testimonial_person_name">Chiara Mayen</p>
    </div>
    <div class="testimonial">
      <div class="testimonial_bubble">
        <p class="testimonial_title">La liberté</p>
        <p class="testimonial_text">Ce système de personnalisation de la carte nous permet de mettre notre menu à jour quand nous le souhaitons.</p>
        <div class="star-disp">
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-half"></i>
        </div>
        <div class="testimonial_bubble_bottom"></div>
      </div>
      <div class="profil_bubble" style="background-image: url(assets/img/landing-page/users/person3.jpeg);">
      </div>
      <p class="testimonial_person_name">Benjamin Bruno</p>
    </div>
    <div class="testimonial">
      <div class="testimonial_bubble">
        <p class="testimonial_title">Facile d'utilisation</p>
        <p class="testimonial_text">L'interface simple et ludique de l'application nous a permit de créer la carte de notre restaurant en moins de 30 minutes. De plus, la fonctionnalité de traduction nous aide avec notre clientelle touristique.</p>
        <div class="star-disp">
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
        </div>
        <div class="testimonial_bubble_bottom"></div>
      </div>
      <div class="profil_bubble" style="background-image: url(assets/img/landing-page/users/person4.jpeg);">
      </div>
      <p class="testimonial_person_name">Nathalie Arthman</p>
    </div>
  </div>
</div>
