<h2>{{ 'landing.menu-qr.our-clients' | translate }}</h2>
<div class="logo-section">
  <div class="white-section-left"></div>
  <div class="white-section-right"></div>
  <a href="https://eatz.fr/menu/pitchoun_cabane" target="_blank">
    <div class="rest-img" style="background-image: url(assets/img/landing-page/logos-rests/pitchoun.jpg);"></div>
  </a>
  <a href="https://eatz.fr/menu/obsession_nice" target="_blank">
    <div class="rest-img" style="background-image: url(assets/img/landing-page/logos-rests/obsession.jpg);"></div>
  </a>
  <a href="https://eatz.fr/menu/nJIBrGLuf0gsbM5ls48E" target="_blank">
    <div class="rest-img" style="background-image: url(assets/img/landing-page/logos-rests/sunset.jpg);"></div>
  </a>
  <a href="https://eatz.fr/menu/l_f_restaurant_nice" target="_blank">
    <div class="rest-img" style="background-image: url(assets/img/landing-page/logos-rests/lf.jpg);"></div>
  </a>
  <a href="https://eatz.fr/menu/dart_bnets" target="_blank">
    <div class="rest-img" style="background-image: url(assets/img/landing-page/logos-rests/dartbnet.jpg);"></div>
  </a>
  <a href="https://eatz.fr/menu/XQCKkUFUlex2s3YFsPsT" target="_blank">
    <div class="rest-img" style="background-image: url(assets/img/landing-page/logos-rests/causette.png);"></div>
  </a>
  <a href="https://eatz.fr/menu/WoT4ayoGM1iYZQ2WJoWn" target="_blank">
    <div class="rest-img" style="background-image: url(assets/img/landing-page/logos-rests/club-player.jpg);"></div>
  </a>
  <a href="https://eatz.fr/menu/CgjBwPzpbrA2LauasvHF" target="_blank">
    <div class="rest-img" style="background-image: url(assets/img/landing-page/logos-rests/flo-khan.jpg);"></div>
  </a>
</div>
