import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logos-section',
  templateUrl: './logos-section.component.html',
  styleUrls: ['./logos-section.component.scss']
})
export class LogosSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
