import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import {TermsComponent} from './modules/home/terms/terms.component';
import {PrivacyComponent} from './modules/home/privacy/privacy.component';
import {LegalsComponent} from './modules/home/legals/legals.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/home/home.module')
      .then(mod => mod.HomeModule) },

  { path: 'landing',
    loadChildren: () => import('./modules/landing/landing.module')
      .then(mod => mod.LandingModule)
  },

  { path: 'terms', component: TermsComponent, },
  { path: 'privacy', component: PrivacyComponent, },
  { path: 'legal', component: LegalsComponent, },

  { path: 'contact-us',
    loadChildren: () => import('./modules/contact-us/contact-us.module')
      .then(mod => mod.ContactUsModule)
  },

  { path: 'presentation',
    loadChildren: () => import('./modules/home/home.module')
      .then(mod => mod.HomeModule)
  },

  {
    path: 'menu',
    loadChildren: () => import('./modules/digital-menu/digital-menu.module')
      .then(mod => mod.DigitalMenuModule)
  },

  /*{
    path: 'waiter',
    loadChildren: () => import('./modules/waiter/waiter.module')
      .then(mod => mod.WaiterModule)
  },*/

  { path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module')
      .then(mod => mod.AdminModule)
  },

  { path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module')
      .then(mod => mod.AuthModule)
  },

  /*{ path: 'delivery',
    loadChildren: () => import('./modules/delivery/delivery.module')
      .then(mod => mod.DeliveryModule)
  },*/

  { path: '**', redirectTo: '', pathMatch: 'full' },
];



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled', initialNavigation: 'enabled'})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
