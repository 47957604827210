<div class="header_menu" [ngClass]="{'header_menu_deployed': header_menu}">
  <div class="header_menu_link_disp">
    <nav role="navigation">
      <details routerLink="/" (click)="header_menu = false">
        <summary>{{ 'landing.accueil' | translate }}</summary>
      </details>
      <details>
        <summary>Services<i class="bi bi-chevron-down"></i></summary>
        <div class="summary-a-disp">
          <a routerLink="/presentation/menu-qr" href="/presentation/menu-qr" (click)="header_menu = false">
            <i class="bi bi-phone"></i>
            <div>
              <p class="summary-a-disp-title">{{ 'landing.services-digital-menu' | translate }}</p>
              <p class="summary-a-disp-p">{{ 'landing.services-digital-menu-t' | translate }}</p>
            </div>
          </a>
          <a routerLink="/marketplace" href="/marketplace" (click)="header_menu = false">
            <i class="bi bi-bag"></i>
            <div>
              <p class="summary-a-disp-title">
                Boutique QR codes
              </p>
              <p class="summary-a-disp-p">Achetez des supports QR code de qualité depuis notre boutique.</p>
            </div>
          </a>
          <!--<a routerLink="/presentation/menu-qr" href="/presentation/menu-qr" (click)="header_menu = false">
            <i class="bi bi-bag"></i>
            <div>
              <p class="summary-a-disp-title">{{ 'landing.services-click-and-collect' | translate }}</p>
              <p class="summary-a-disp-p">{{ 'landing.services-click-and-collect-t' | translate }}</p>
            </div>
          </a>
          <a routerLink="/presentation/paiement" (click)="header_menu = false">
            <i class="bi bi-credit-card-2-front"></i>
            <div>
              <p class="summary-a-disp-title">{{ 'landing.services-paiement' | translate }}</p>
              <p class="summary-a-disp-p">{{ 'landing.services-paiement-t' | translate }}</p>
            </div>
          </a>
          <a routerLink="/presentation/marketplace" (click)="header_menu = false">
            <i class="bi bi-person-badge"></i>
            <div>
              <p class="summary-a-disp-title">{{ 'landing.services-room-service' | translate }}</p>
              <p class="summary-a-disp-p">{{ 'landing.services-room-service-t' | translate }}</p>
            </div>
          </a>-->
        </div>
      </details>
      <details routerLink="/prices" (click)="header_menu = false">
        <summary>{{ 'landing.prices' | translate }}</summary>
      </details>
      <details routerLink="/contact-us" style="border-bottom: none;" (click)="header_menu = false">
        <summary>{{ 'landing.contact-us' | translate }}</summary>
      </details>
    </nav>
  </div>
  <div class="header_menu_connexion_disp">
    <a routerLink="/auth/rest-signup" href="/auth/rest-signup" (click)="header_menu = false">
      <button class="presentation_button presentation_button_black">
        {{ 'landing.registration' | translate }}
      </button>
    </a>
    <a routerLink="/admin" href="/admin" (click)="header_menu = false">
      <button class="presentation_button presentation_button_borderBlack">
        {{ 'landing.connection' | translate }}
      </button>
    </a>
  </div>
</div>

<div class="presentation_header" [ngClass]="{'presentation_header_deployed': header}">
  <div class="eatz_page_width">
    <a routerLink="/" href="/">
      <img alt="logo EAT'Z" src="/../../../assets/img/Eat'z_logo.png">
    </a>
    <div class="presentation_header_link_disp">
      <nav role="navigation" id="nav-bar-computer">
        <ul>
          <li><a routerLink="/" href="/">{{ 'landing.accueil' | translate }}</a></li>
          <!--<li><a routerLink="/delivery" href="/delivery">{{ 'landing.order' | translate }}</a></li>-->
          <li><a routerLink="/presentation" href="/presentation">{{ 'landing.services' | translate }}<i class="bi bi-chevron-down"></i></a>
            <ul>
              <li>
                <a routerLink="/presentation/menu-qr" href="/presentation/menu-qr">
                  <i class="bi bi-phone"></i>
                  {{ 'landing.services-digital-menu' | translate }}
                </a>
              </li>
              <li>
                <a routerLink="/marketplace" href="/marketplace">
                  <i class="bi bi-bag"></i>
                  Boutique QR codes
                </a>
              </li>
              <!--<li>
                <a routerLink="/presentation/paiement" href="/presentation/paiement">
                  <i class="bi bi-bag"></i>
                  {{ 'landing.services-click-and-collect' | translate }}
                </a>
              </li>
              <li>
                <a routerLink="/presentation/paiement">
                  <i class="bi bi-credit-card-2-front"></i>
                  {{ 'landing.services-paiement' | translate }}
                </a>
              </li>
              <li>
                <a routerLink="/presentation/marketplace">
                  <i class="bi bi-person-badge"></i>
                  {{ 'landing.services-room-service' | translate }}
                </a>
              </li>--> <!--{{ 'landing.services-click-and-collect' | translate }}-->
            </ul>
          </li>
          <li><a routerLink="/contact-us" href="/contact-us">{{ 'landing.contact-us' | translate }}</a></li>
          <li><a routerLink="/prices" href="/contact-us">{{ 'landing.prices' | translate }}</a></li>
        </ul>
      </nav>
    </div>

    <div class="presentation_header_connection_disp">
      <div class="lang_button" (click)="lang_modal = !lang_modal;" style="background-image: url(/../../../../assets/img/flags/{{lang}}.jpg)"></div>
      <a routerLink="/admin" href="/admin" style="margin-right: 10px;">
        <button class="presentation_button presentation_button_borderBlack">
          {{ 'landing.connection' | translate }}
        </button>
      </a>
      <a routerLink="/auth/rest-signup" href="/auth/rest-signup">
        <button class="presentation_button presentation_button_black">
          {{ 'landing.registration' | translate }}
        </button>
      </a>
    </div>
    <i class="bi bi-list" *ngIf="!header_menu" (click)="header_menu = true"></i>
    <i class="bi bi-x-lg" *ngIf="header_menu" (click)="header_menu = false"></i>
    <a class="connection_mobile" routerLink="/auth/signin">Se connecter</a>
  </div>
</div>


<!--<div class="contact_bubble" routerLink="/contact-us" [ngStyle]="{'padding': chat_bubble ? '0' : '15px'}"> &lt;!&ndash;(click)="chat_bubble = !chat_bubble"&ndash;&gt;
  &lt;!&ndash;<svg xmlns="http://www.w3.org/2000/svg" width="26" height="21.337" viewBox="0 0 26 21.337">
    <g id="Group_7" data-name="Group 7" transform="translate(-227.925 -732)">
      <rect id="Rectangle_9" data-name="Rectangle 9" width="26" height="16" rx="8" transform="translate(227.925 732)" fill="#1f3eb4"/>
      <path id="Polygon_1" data-name="Polygon 1" d="M2.836,0,5.671,5.671H0Z" transform="matrix(-0.966, 0.259, -0.259, -0.966, 247.525, 751.87)" fill="#1f3eb4"/>
    </g>
  </svg>
  <p>Une question ?</p>&ndash;&gt;
  <i class="bi bi-chat-left-fill" *ngIf="!chat_bubble"></i>
  <i class="bi bi-x" style="font-size: 50px;" *ngIf="chat_bubble"></i>
</div>

<div class="chat_bubble" *ngIf="chat_bubble">

</div>-->

<div class="lang_modal_shadow" *ngIf="lang_modal" (click)="lang_modal = !lang_modal"></div>
<div [ngClass]="{'lang_modal': lang_modal, 'lang_modal_hidden': !lang_modal}">
  <button type="button" class="covid_tracer_button" (click)="lang_modal = false" class="cancel_lang_button"><i class="bi bi-x"></i></button>
  <h2>{{ 'admin.footer.translation' | translate }}</h2>
  <div class="flag_disp">
    <div class="flag" id="fr" (click)="update_lang('fr')"></div>
    <div class="flag" id="en" (click)="update_lang('en')"></div>
    <div class="flag" id="it" (click)="update_lang('it')"></div>
    <div class="flag" id="es" (click)="update_lang('es')"></div>
    <div class="flag" id="de" (click)="update_lang('de')"></div>
    <div class="flag" id="he" (click)="update_lang('he')"></div>
    <div class="flag" id="zh" (click)="update_lang('zh')"></div>
    <div class="flag" id="ru" (click)="update_lang('ru')"></div>
    <div class="flag" id="ar" (click)="update_lang('ar')"></div>
    <div class="flag" id="pt" (click)="update_lang('pt')"></div>
  </div>
</div>
