import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {AuthService} from './services/auth.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'eatzWeb';
  version = 'Angular version 11.xxx';

  UserCache: any = [];
  UserCacheSubscription: Subscription;

  constructor(private translate: TranslateService,
              private authService: AuthService) {
    if (['fr', 'en', 'it', 'es', 'de', 'he', 'zh', 'ru', 'ar', 'pt'].indexOf(this.translate.getBrowserLang()) !== -1) {
      translate.setDefaultLang(this.translate.getBrowserLang());
    } else {
      translate.setDefaultLang('en');
    }
  }

  ngOnInit() {
    this.UserCacheSubscription = this.authService.userCacheSubject.subscribe(
      (UserCache: any[]) => {
        this.UserCache = UserCache;
        console.log(this.UserCache)
      }
    );
    this.authService.emitUserCacheSubject();

    firebase.default.auth().onAuthStateChanged(
      () => {
        this.authService.updateUserCache(firebase.default.auth().currentUser);}
    )
  }

  ngOnDestroy() {
    this.UserCacheSubscription.unsubscribe();
  }
}
