<div class="stats_section">
  <div class="page_width_lp">
    <div class="stats_half stats_left">
      <h2>{{ 'landing.exp' | translate }}</h2>
      <p>{{ 'landing.exp-t' | translate }}</p>
    </div>
    <div class="stats_half">
      <div class="stats_disp">
        <div class="stat_box">
          <!--<img src="assets/img/rest_covers/13.svg">-->
          <i class="bi bi-people"></i>
          <div class="stat_description">
            <h3>+10,000</h3>
            <p>{{ 'landing.exp-1' | translate }}</p>
          </div>
        </div>
        <div class="stat_box">
          <!--<img src="assets/img/rest_covers/14.svg">-->
          <i class="bi bi-globe2"></i>
          <div class="stat_description">
            <h3>+5 000</h3>
            <p>{{ 'landing.exp-2' | translate }}</p>
          </div>
        </div>
        <div class="stat_box">
          <!--<img src="assets/img/rest_covers/15.svg">-->
          <i class="bi bi-shop"></i>
          <div class="stat_description">
            <h3>+100</h3>
            <p>{{ 'landing.exp-3' | translate }}</p>
          </div>
        </div>
        <div class="stat_box">
          <!--<img src="assets/img/rest_covers/16.svg">-->
          <i class="bi bi-signpost-split"></i>
          <div class="stat_description">
            <h3>140</h3>
            <p>{{ 'landing.exp-4' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
