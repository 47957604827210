<app-header></app-header>

<div class="page-font">
  <div class="terms_header">
    <h1>MENTIONS LÉGALES</h1>
  </div>
  <div class="terms_width">
    <p>
      Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l’économie numérique, il est précisé aux utilisateurs du site Eat’z l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
      <br>
      <strong>Edition du site</strong>
      <br>
      Le présent site, accessible à l’URL <a routerLink="/">www.eatz.fr</a> (le « Site »), est édité par :
      <br>
      Eatz SAS société au capital de 250 euros, inscrite au R.C.S. de Nice sous le numéro 891 803 702, dont le siège social est situé au 73 avenue Raymond Ferraud, 06200 Nice, représenté(e) par M BOUILLARD Thierry dûment habilité(e),
      Le numéro individuel TVA de l’Exploitant est : FR89891803702
      <br>
      <strong>Hébergement</strong>
      <br>
      Le Site est hébergé par la société Google domains, situé, 8 Rue de Londres, 75009 Paris.
      <br>
      <strong>Directeur de publication</strong>
      <br>
      Le Directeur de la publication du Site est M Dan CASTIEL.
      <br>
      <strong>Nous contacter</strong>
      <br>
      Par téléphone : ‭06 26 77 09 57
      Par email : dan.castiel@eatz.fr
      Par courrier : Eatz SAS 73 avenue Raymond Ferraud, 06200 Nice
      <br>
      <strong>Données personnelles</strong>
      <br>
      Le traitement de vos données à caractère personnel est régi par notre <a routerLink="/privacy">Charte du respect de la vie privée</a> conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 (« RGPD »).
    </p>
  </div>
</div>

<app-footer></app-footer>
