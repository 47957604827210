<h2>{{ 'landing.menu-qr.faq.title1' | translate }} <br> {{ 'landing.menu-qr.faq.title2' | translate }}</h2>

<div class="faq-disp">
  <div class="faq-box">
    <p class="faq-question">{{ 'landing.menu-qr.faq.q1' | translate }}</p>
    <p class="faq-answer">{{ 'landing.menu-qr.faq.q1-t' | translate }} <b>{{ 'landing.menu-qr.faq.q1-t-b' | translate }}</b>. </p>
  </div>
  <div class="faq-box">
    <p class="faq-question">{{ 'landing.menu-qr.faq.q2' | translate }}</p>
    <p class="faq-answer">{{ 'landing.menu-qr.faq.q1-t' | translate }} <b>{{ 'landing.menu-qr.faq.q1-t-b' | translate }}</b>.</p>
  </div>
  <div class="faq-box">
    <p class="faq-question">{{ 'landing.menu-qr.faq.q3' | translate }}</p>
    <p class="faq-answer">{{ 'landing.menu-qr.faq.q3-t' | translate }} <i>{{ 'landing.menu-qr.faq.q3-t-i' | translate }} </i></p>
  </div>
  <div class="faq-box">
    <p class="faq-question">{{ 'landing.menu-qr.faq.q4' | translate }}</p>
    <p class="faq-answer">{{ 'landing.menu-qr.faq.q4-t' | translate }} <a>https://eatz.fr/menu/<i>{{ 'landing.menu-qr.faq.q4-t-i' | translate }}</i></a>. {{ 'landing.menu-qr.faq.q4-t2' | translate }}</p>
  </div>
  <div class="faq-box">
    <p class="faq-question">{{ 'landing.menu-qr.faq.q5' | translate }}</p>
    <p class="faq-answer">{{ 'landing.menu-qr.faq.q5-t' | translate }} <i>{{ 'landing.menu-qr.faq.q5-t-i' | translate }} <a>https://eatz.fr/menu/nom-de-votre-restaurant</a></i></p>
  </div>
  <div class="faq-box">
    <p class="faq-question">{{ 'landing.menu-qr.faq.q6' | translate }}</p>
    <p class="faq-answer">{{ 'landing.menu-qr.faq.q6-t' | translate }}
      <a href="https://eatz.fr/admin" routerLink="/admin">https://eatz.fr/admin</a>.</p>
  </div>
  <div class="faq-box">
    <p class="faq-question">{{ 'landing.menu-qr.faq.q7' | translate }}</p>
    <p class="faq-answer">{{ 'landing.menu-qr.faq.q7-t' | translate }}</p>
  </div>
  <div class="faq-box">
    <p class="faq-question">{{ 'landing.menu-qr.faq.q8' | translate }}</p>
    <p class="faq-answer">{{ 'landing.menu-qr.faq.q8-t1' | translate }} <b>{{ 'landing.menu-qr.faq.q8-t-b' | translate }}</b> : {{ 'landing.menu-qr.faq.q8-t2' | translate }}</p>
  </div>
  <div class="faq-box">
    <p class="faq-question">{{ 'landing.menu-qr.faq.q9' | translate }}</p>
    <p class="faq-answer">{{ 'landing.menu-qr.faq.q9-t1' | translate }} <i>{{ 'landing.menu-qr.faq.q9-t-i' | translate }}</i>{{ 'landing.menu-qr.faq.q9-t2' | translate }}</p>
  </div>
  <div class="faq-box">
    <p class="faq-question">{{ 'landing.menu-qr.faq.q10' | translate }}</p>
    <p class="faq-answer">{{ 'landing.menu-qr.faq.q10-t' | translate }}</p>
  </div>
  <div class="faq-box">
    <p class="faq-question">{{ 'landing.menu-qr.faq.q11' | translate }}</p>
    <p class="faq-answer">{{ 'landing.menu-qr.faq.q11-t' | translate }}</p>
  </div>
  <div class="faq-box">
    <p class="faq-question">{{ 'landing.menu-qr.faq.q12' | translate }}</p>
    <p class="faq-answer">{{ 'landing.menu-qr.faq.q12' | translate }}</p>
  </div>
</div>
