import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features-examples-digital-menu',
  templateUrl: './features-examples-digital-menu.component.html',
  styleUrls: ['./features-examples-digital-menu.component.scss']
})
export class FeaturesExamplesDigitalMenuComponent implements OnInit {

  //avantages
  av1 = true;
  av2 = false;
  av3 = false;
  av4 = false;

  constructor() { }

  ngOnInit(): void {
    window.setInterval(() => {
      if (this.av1) {
        this.avFalse();
        this.av2 = true;
      } else if (this.av2) {
        this.avFalse();
        this.av3 = true;
      } else if (this.av3) {
        this.avFalse();
        this.av4 = true;
      } else if (this.av4) {
        this.avFalse();
        this.av1 = true;
      }

    }, 10000);
  }

  avFalse() {
    this.av1 = false;
    this.av2 = false;
    this.av3 = false;
    this.av4 = false;
  }

}
