import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import * as firebase from "firebase";
import {formatDate, Location} from '@angular/common';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private adminCache: any = [];
  private adminPreferences: any = [];
  private adminRestaurants: any = [];
  adminCacheSubject = new Subject<any[]>();

  private currentUser: any = [{name : 'ds', photo_url: ''}];
  currentUserSubject = new Subject<any[]>();

  private dishes: any = [];
  adminDishesSubject = new Subject<any[]>();

  private categories: any = [];
  adminCategoriesSubject = new Subject<any[]>();

  private orders: any = [];
  adminOrdersSubject = new Subject<any[]>();

  private notification = [{status: false, message: ''}];
  adminNotificationsSubject = new Subject<any[]>();

  constructor(private router: Router,
              private _location: Location) { }


  emitAdminCacheSubject() {
    this.adminCacheSubject.next(this.adminCache.slice());
  }

  emitCurrentUserSubject() {
    this.currentUserSubject.next(this.currentUser.slice());
  }

  emitAdminDishesSubject() {
    this.adminDishesSubject.next(this.dishes.slice());
  }

  emitAdminCategoriesSubject() {
    this.adminCategoriesSubject.next(this.categories.slice());
  }

  emitAdminOrdersSubject() {
    this.adminOrdersSubject.next(this.orders.slice());
  }

  emitAdminNotificationsSubject() {
    this.adminNotificationsSubject.next(this.notification.slice());
  }

  getCurrentUserData() {
    this.currentUser.push({name : 'test', photo_url: ''});
    this.emitCurrentUserSubject();
  }

  getAdminCache() {
    const user_id = firebase.default.auth().currentUser.uid;
    this.adminCache = [];
    this.adminPreferences = [];
    this.adminRestaurants = [];
    //GET THE USER CACHE
    firebase.default.firestore().doc('users/' + user_id + '/preferences/cache')
      .get()
      .then(
        (doc) => {
          if (doc.data() != undefined) {
            this.adminPreferences.push({rest_id: doc.data().id, rest_name: doc.data().name, background_image: doc.data().background_image, logo: doc.data().logo, rest_address: doc.data().address, admin: doc.data().admin, tutorial_status: doc.data().tutorial_status, owner_id: doc.data().owner_id});
          } else {
            this.adminPreferences.push({rest_id: '', rest_name: '', background_image: '', logo: '', rest_address: '', admin: true});
          }
          //GET THE LIST OF RESTAURANTS THAT THE ADMIN CAN INTERACT WITH
          firebase.default.firestore().collection('users/' + user_id + '/restaurants/')
            .get()
            .then(
              (querySnapshot) => {
                querySnapshot.forEach(
                  (doc) => {
                    firebase.default.firestore().doc('restaurants/' + doc.data().id + '/settings/id_card')
                      .get().then((value) => {
                      if (value.data() != undefined) {
                        let stripe_account_id: string;
                        if (value.data().stripe_account_id != undefined) {
                          stripe_account_id = value.data().stripe_account_id;
                        } else {
                          stripe_account_id = '';
                        }
                        let stripe_transfer: boolean;
                        if (value.data().stripe_transfer != undefined) {
                          stripe_transfer = value.data().stripe_transfer;
                        } else {
                          stripe_transfer = false;
                        }

                        // GET THE SUBSCRIPTION STATUS
                        firebase.default.firestore().doc('restaurants/' + this.adminCache[0].preferences[0].rest_id).get().then(
                          (rest) => {
                            let subscribed = false;
                            if (rest.data().subscribed) {
                              subscribed = true;
                            }
                            this.adminRestaurants.push({id: doc.data().id, background_image: value.data().background_image, logo: value.data().logo, name: value.data().name, address: value.data().address, stripe_account_id: stripe_account_id, stripe_transfer: stripe_transfer, subscribed: subscribed});
                          })
                      }
                    })
                  })
              })
            .then(
            () => {
              if (this.adminRestaurants.length === 0) {
                this.adminRestaurants.push({id: '', background_image: '', logo: '', name: '', address: '', stripe_account_id: '', stripe_transfer: '', subscribed: false});
              }
              this.adminCache.push({preferences: this.adminPreferences, restaurants: this.adminRestaurants});
              this.emitAdminCacheSubject();

              this.getDishes(this.adminCache[0].preferences[0].rest_id);

              if (this.adminPreferences[0].rest_id != '') { // Possible bug : avant --> this.adminRestaurants[0].id
                this.getOrders(this.adminPreferences[0].rest_id)
              } else {
                this.orders = [];
                this.emitAdminOrdersSubject();
              }
            }
          )

        })
  }

  changeRestaurant(user_id: string, rest_id: string) {
    firebase.default.firestore().doc('restaurants/' + rest_id + '/settings/users')
      .get()
      .then(
        (doc) => {
          let role = doc.data().roles[user_id];
          firebase.default.firestore().doc('restaurants/' + rest_id + '/settings/id_card')
            .get()
            .then(
              (doc) => {
                var logo = '';
                if (doc.data().logo != undefined) {
                  logo = doc.data().logo;
                }

                var background_image = '';
                if (doc.data().background_image != undefined) {
                  background_image = doc.data().background_image;
                }
                //Then UPDATE ADMIN CACHE
                firebase.default.firestore().doc('users/' + user_id + '/preferences/cache')
                  .update({admin: role, address: doc.data().address, background_image: background_image, logo: logo, name: doc.data().name, id: rest_id})
              }).then(
            () => {
              this.getAdminCache();
              this.getDishes(rest_id);
              this.getCategories(rest_id);
              this.getOrders(this.adminPreferences[0].rest_id)
            }
          )
        })
  }


  getDishes(rest_id: string) {
    this.dishes = [];

    firebase.default.firestore().collection('restaurants/' + rest_id + '/dishes').orderBy('createdAt', "desc")
      .get()
      .then(
        (querySnapshot) => {
          querySnapshot.forEach(
            (doc) => {
              this.addDishes(doc.id, doc.data().name, doc.data().featured, doc.data().description, doc.data().price, doc.data().status, doc.data().photo, doc.data().dish_type, doc.data().allergens, doc.data().category);
            })
        })
      .then( //FOR THE OLD ONES WHO DOESNT HAVE THE "CREATEDAT" FIELS YET
      () => {
        firebase.default.firestore().collection('restaurants/' + rest_id + '/dishes')
          .get()
          .then(
            (querySnapshot) => {
              querySnapshot.forEach(
                (doc) => {
                  if(doc.data().createdAt == null) {
                    this.addDishes(doc.id, doc.data().name, doc.data().featured, doc.data().description, doc.data().price, doc.data().status, doc.data().photo, doc.data().dish_type, doc.data().allergens, doc.data().category);
                  }
                })
            })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });})
  }

  addDishes(id: string, name: string, featured: boolean, description: string, price: number, status: boolean, photo: string, type: string, allergens: any, category: any) {
    this.dishes.push({id, name, featured, description, price, status, photo, type, allergens, category});
    this.emitAdminDishesSubject();
  }

  deleteDish(rest_id: string, dish_id: string) {
    firebase.default.firestore().doc('restaurants/' + rest_id + '/dishes/' + dish_id)
      .delete()
      .then(
        (value) => {
          this.triggerNotification('dishDeleted');
        })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
  }

  //CATEGORIES
  getCategories(rest_id: string) {
    this.categories = [];

    firebase.default.firestore().collection('restaurants/' + rest_id + '/categories').orderBy("order", "asc")
      .get()
      .then(
        (querySnapshot) => {
          querySnapshot.forEach(
            (doc) => {
              this.addCategories(doc.id, doc.data().name, doc.data().photo, doc.data().status, doc.data().info, doc.data().subcats, doc.data().dishes_ID)
            })
        })
  }

  addCategories(id: string, name: string, photo: string, status: boolean, info: string, subcats: any, dishes_ID: any) {
    this.categories.push({id, name, status, photo, info, subcats, dishes_ID});
    this.emitAdminCategoriesSubject();
  }

  deleteCategory(i: number) {
    this.categories.splice(i, 1);
    this.emitAdminCategoriesSubject();
  }

  uploadRestFile(file: File, path: string) {
    return new Promise(
      (resolve, reject) => {
        const almostUniqueFileName = Date.now().toString();
        const fileName = almostUniqueFileName + file.name
        const upload = firebase.default.storage().ref()
          .child('images/' + path + '/' + almostUniqueFileName)
          .put(file);
        upload.on(firebase.default.storage.TaskEvent.STATE_CHANGED,
          () => {
            console.log('Chargement...')
          },
          (error) => {
            console.log('Erreur de chargement: ' + error)
            reject();
          },
          () => {
            resolve(upload.snapshot.ref.getDownloadURL()); // upload.snapshot.ref.getDownloadURL()
          }
        );
      }
    );
  }

  getOrders(rest_id: string) {
    this.orders = [];
    this.emitAdminOrdersSubject();

    firebase.default.firestore().collection('restaurants/' + rest_id + '/orders')
      .where("created_on", "==", formatDate(new Date(), 'yyyy-MM-dd', 'en'))
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            this.orders.push(
              {
                created_on: change.doc.data().created_on,
                created_at: change.doc.data().created_at,
                items: change.doc.data().items,
                order_client_name: change.doc.data().order_client_name,
                order_status: change.doc.data().order_status,
                order_total: change.doc.data().order_total,
                order_type: change.doc.data().order_type,
              }
            )
            // let audio = new Audio('../assets/sounds/UIAlert_Notification lasolisa 4 (ID 2066)_LS.mp3');
            // audio.play();
          }
          if (change.type === "modified") {
            let i = 0;
            for (let order of this.orders) {
              if (order.order_invoice_id === change.doc.data().order_invoice_id) {
                this.orders[i] = change.doc.data();
                let audio = new Audio('../assets/sounds/UIAlert_Notification lasolisa 4 (ID 2066)_LS.mp3');
                audio.play();
              }
              i=i+1
            }
          }
          if (change.type === "removed") {
            let i = 1;
            for (let order of this.orders) {
              if (order.order_invoice_id === change.doc.data().order_invoice_id) {
                this.orders.splice(i, 1);
                let audio = new Audio('../assets/sounds/UIAlert_Notification lasolisa 3 (ID 2065)_LS.mp3');
                audio.play();
              }
              i=i+1
            }
          }
          this.emitAdminOrdersSubject();
        });
      });
  }

  triggerNotification(object: string) { //Notification systeme in the bottom of the page
    if (object === 'categoryCreated') {
      this.notification = [{status: true, message: 'La catégorie a été crée'}]
    } else if (object === 'categoryUpdated') {
      this.notification = [{status: true, message: 'La catégorie a été mise à jour'}]
    } else if (object === 'categoryDeleted') {
      this.notification = [{status: true, message: 'La catégorie a été supprimée'}]
    } else if (object === 'dishCreated') {
      this.notification = [{status: true, message: 'Le plat a été crée'}]
    } else if (object === 'dishUpdated') {
      this.notification = [{status: true, message: 'Le plat a été mis à jour'}]
    } else if (object === 'dishDeleted') {
      this.notification = [{status: true, message: 'Le plat a été supprimé'}]
    } else if (object === 'categoriesOrderUpdated') {
      this.notification = [{status: true, message: 'L\'ordre des catégories a été mis à jour'}]
    }
    this.emitAdminNotificationsSubject();

    setTimeout(()=> {
      this.notification = [{status: false, message: ''}];
      this.emitAdminNotificationsSubject();
    }, 5000)
  }

  updateCategory(rest_id, category_id, name: any, info: any, description: any, photo: string, subcats: any) {
    firebase.default.firestore().collection('restaurants/' + rest_id + '/categories').doc(category_id).update(
      {
        name: name,
        info: info,
        description: description,
        photo: photo,
        //order: this.categories.length,
        subcats: subcats})
      .then(
        (value) => {
          this.triggerNotification('categoryUpdated');
        })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  }

  updateDishStatus(dish, i) {
    firebase.default.firestore().doc('restaurants/' + this.adminPreferences[0].rest_id + '/dishes/' + dish.id)
      .update({
        status: this.dishes[i].status
      })
      .then(
        (value) => {
          this.triggerNotification('dishUpdated');
        })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
  }

  createDish(name: any, description: any, price: any, photo_url: any, info: any, status: string, show_first: any, category: any[], subcats: any, flavour: any[], variants: any[], variants_names: any[], allergens: any[]) {
    firebase.default.firestore().collection('restaurants/' + this.adminCache[0].preferences[0].rest_id + '/dishes')
      .add({name: name, description: description, price: price, photo: photo_url, info: info, status: true, show_first: show_first, category: category, subcats: subcats, flavour: flavour, variants: variants, variants_names: variants_names, allergens: allergens, createdAt: firebase.default.firestore.FieldValue.serverTimestamp()})
      .then(
        (value) => {
          //this.router.navigate(['/admin', 'admin-menu-category-view', value.id]);
          this._location.back();
          this.triggerNotification('dishCreated');
          this.getDishes(this.adminCache[0].preferences[0].rest_id);
        })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
  }

  updateCategoriesOrder(categories) {
    let i = 0;
    this.notification= [{status: true, message: 'Mise à jour de l\'ordre de catégories en cours...'}];
    this.emitAdminNotificationsSubject();

    for(let category of categories) {
      firebase.default.firestore().doc('restaurants/' + this.adminCache[0].preferences[0].rest_id + '/categories/' + category.id).update({order: i})
      i = i + 1;
    }
    this.triggerNotification('categoriesOrderUpdated');
  }
}
