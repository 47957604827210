<app-header [transparent]="true"></app-header>

<div class="page_font">
  <div class="block">
    <h1>Exploitez tout le potentiel de Eat'z.</h1>

    <div class="block-container">
      <h2 style="width: fit-content; margin: auto; margin-bottom: 15px; color: var(--eatz-vert-fonce); border-bottom: 2px solid var(--eatz-vert-fonce);">Menu numérique</h2>

      <div class="div-select-price">
        <button [ngClass]="{'price-button-selected': priceMonth}" id="month" (click)="priceMonth = true">Sans engagement</button>
        <button [ngClass]="{'price-button-selected': !priceMonth}" id="year" (click)="priceMonth = false">Engagement 1an</button>
      </div>

      <div class="prices_disp">
        <div class="price_box">
          <p class="price-title">Solo</p>
          <p class="price-description">{{ 'landing.services-digital-menu-t' | translate }}</p>
          <p class="price-price" *ngIf="priceMonth">60€</p>
          <p class="price-price" *ngIf="!priceMonth">45€</p>
          <p class="price-price-t">/ mois</p>
          <button type="button" class="price-button" routerLink="/auth/rest-signup" style="background-color: var(--eatz-vert-fonce); color: #fff">S'inscrire gratuitement</button>
          <!--<details class="price-details">
            <summary>Toutes les fonctionnalités.</summary>
            test
          </details>-->
        </div>
        <div class="price_box">
          <p class="price-title">Entreprise</p>
          <p class="price-description">Vous avez plusieurs établissements, vous pouvez bénéficier d'une offre groupée. Contactez-nous pour en parler.</p>
          <p class="price-price">Parlons-en.</p>
          <button type="button" class="price-button" routerLink="/contact-us">Contacter un expert Eat'z</button>
        </div>
      </div>
    </div>

    <h2 style="width: fit-content; margin: auto; margin-bottom: 15px; color: var(--eatz-vert-fonce); border-bottom: 2px solid var(--eatz-vert-fonce); margin-top: 60px;">Autres</h2>
    <div class="prices_disp" style="flex-direction: column; max-width: 800px; margin: auto; padding: 15px">
      <div class="price_box" style="min-width: 100%; margin-bottom: 15px;">
        <p class="price-price">On rentre votre carte</p>
        <p class="price-title">Devis sur mesure</p>
        <button type="button" class="price-button" routerLink="/contact-us">Contacter un expert Eat'z</button>
      </div>
      <div class="price_box" style="min-width: 100%;">
        <p class="price-price">Supports QR Codes</p>
        <p class="price-title">Dépend du modèle</p>
        <button type="button" class="price-button" routerLink="/marketplace">Voir les supports</button>
      </div>
    </div>
  </div>

  <div class="contact-us-section">
    <div class="contact-us-image"></div>
    <div class="contact-us-box">
      <h3>Dites nous comment vous accompagner !</h3>

      <div class="contact-us-box-disp">
        <div class="contact-us-box-image-box">
          <img src="assets/img/landing-page/dan.png" alt="Photo de Dan">
          <img src="assets/img/landing-page/thierry.png" alt="Photo de Jade">
        </div>
        <div class="contact-us-text-box">
          <p>
            Besoin d’accompagnement ? Une question ?
            <br>
            Dan et Thierry sont là pour vous, de 9h à 18h, jours ouvrés.
          </p>

          <div class="contact-us-buttons-box">
            <button type="button" class="price-button" id="tidio-trigger" style="background-color: #000; color: #fff;"><i class="bi bi-chat-dots"></i>Contacter par chat</button>
            <button type="button" class="price-button" id="mail-button" routerLink="/contact-us"><i class="bi bi-envelope"></i>Contacter par mail</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<app-footer></app-footer>
