<h2>{{ 'landing.menu-qr.menu-example-title' | translate }}</h2>

<div class="example-disp">
  <div class="example">
    <a target="_blank" href="/menu/lou_ginestre_restaurant">
      <div class="example-box">
        <img class="hero-menu-img" src="/../assets/img/landing-page/lou-ginestre-menu.png" alt="Menu EAT'Z">
      </div>
    </a>
    <p class="rest-name">Lou Ginestre</p>
    <p class="rest-description">{{ 'landing.menu-qr.menu-example-LG' | translate }}</p>
  </div>
  <div class="example">
    <a target="_blank" href="/menu/nJIBrGLuf0gsbM5ls48E">
      <div class="example-box">
        <img class="hero-menu-img" src="/../assets/img/landing-page/sunset-menu.png" alt="Menu EAT'Z">
      </div>
    </a>
    <p class="rest-name">Le Sunset</p>
    <p class="rest-description">{{ 'landing.menu-qr.menu-example-LS' | translate }}</p>
  </div>
  <div class="example">
    <a target="_blank" href="/menu/XQCKkUFUlex2s3YFsPsT">
      <div class="example-box">
        <img class="hero-menu-img" src="/../assets/img/landing-page/menu-iphone-x.png" alt="Menu EAT'Z">
      </div>
    </a>
    <p class="rest-name">Causette & Bergamotte</p>
    <p class="rest-description">{{ 'landing.menu-qr.menu-example-CB' | translate }}</p>
  </div>
  <div class="example">
    <a target="_blank" href="/menu/CgjBwPzpbrA2LauasvHF">
      <div class="example-box">
        <img class="hero-menu-img" src="/../assets/img/landing-page/florence-khan-menu.png" alt="Menu EAT'Z">
      </div>
    </a>
    <p class="rest-name">Florence Khan</p>
    <p class="rest-description">{{ 'landing.menu-qr.menu-example-FK' | translate }}</p>
  </div>
</div>
