<!--<h2 class="title_h2">{{ 'landing.our-services' | translate }}</h2>-->
<div class="features_disp">
  <div class="feature-img-box">
    <img src="/../assets/img/landing-page/menu-iphone-x.png" alt="Menu EAT'Z">
  </div>
  <div class="feature-description">
    <h2>L’outil innovant pour :</h2>
    <ul>
      <li>S’ouvrir à une <b>clientèle internationale</b></li>
      <li>Gagner du temps en <b>fluidifiant le service</b></li>
      <li>Améliorer votre <b>visibilité sur internet</b></li>
      <li><b>Fidéliser</b> en créant une expérience unique</li>
  </ul><!--{{ 'landing.digital-menu-p' | translate }}-->
    <div class="a-disp">
      <a routerLink="/presentation/menu-qr">{{ 'landing.know-more-digital-menu' | translate }}</a>
      <a routerLink="/auth/rest-signup" class="a-button-cma">{{ 'landing.menu-qr.create-account' | translate }}</a>
    </div>
  </div>
</div>
