<div class="project-box">
  <div class="project-box-description">
    <h2>45€ / mois - seulement</h2>
    <!--{{ 'landing.menu-qr.price-title' | translate }}-->
    <!--<p>{{ 'landing.menu-qr.price-p' | translate }}</p>-->
  </div>
  <img class="pattern-img" src="/../assets/img/landing-page/pattern-price.svg" alt="pattern price">
  <img class="menu-img" src="/../assets/img/landing-page/menu-iphone-x.png" alt="Menu EAT'Z">
</div>

<div style="height: 30px"></div>
