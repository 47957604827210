<div class="footer">
  <div class="footer_max">
    <div class="footer_bigLinks">
      <a href="/presentation">{{ 'footer.create-card' | translate }}</a>
      <a href="/contact-us">{{ 'footer.contact-us' | translate }}</a>
      <a *ngIf="!isAuth" href="/admin">{{ 'footer.connection' | translate }}</a>
      <a *ngIf="isAuth" href="/admin">{{ 'footer.administration' | translate }}</a>
    </div>
    <!--<a routerLink="/"><img src="/assets/img/Eat'Z.png" alt="by Eat'z"></a>
    <div class="footer_info">
      <a *ngIf="!isAuth" routerLink="/admin"><i class="glyphicon glyphicon-log-in"></i>Se connecter</a>
      <a *ngIf="isAuth" routerLink="/admin"><i class="glyphicon glyphicon-dashboard"></i>Administration</a>
      <a routerLink="/contact-us"><i class="glyphicon glyphicon-send"></i>Nous contacter</a>
      <a routerLink=""><i class="glyphicon glyphicon-blackboard"></i>Présentation EAT'Z</a>
    </div>-->

    <span class="line_span"></span>

    <div class="footer_mentions">
      <div class="footer_mentions_a_disp">
        <a href="/terms">Conditions générales</a>
        <a href="/privacy">{{ 'footer.privacy' | translate }}</a>
        <a href="/legal">Mentions légales</a>
      </div>
      <p>® 2021 <a href="">EAT'Z</a>. {{ 'footer.all-rights-reserved' | translate }}</p>
    </div>
  </div>
</div>
