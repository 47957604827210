<div class="page_font hero-pf">
  <app-header
  [transparent]="true"></app-header>
  <div class="landing_view">
    <div class="landing_view_bg">
      <div class="landing_view_bg_creme">
        <div class="landing_view_bg_blur"></div>
        <!--<div class="landing_view_bg_creme_rotate"></div>-->
        <div class="landing_view_bg_green_left"></div>
        <div class="landing_view_bg_rose_left"></div>

<!--        <div class="landing_view_bg_corail_right"></div>
        <div class="landing_view_bg_rose_right"></div>
        <div class="landing_view_bg_vert_right"></div>-->
      </div>
    </div>
    <div class="page_view_content">
      <div class="hero-width">
        <div class="landing_view_disp">
          <div class="landing_view_side landing_view_left">
            <div class="landing_view_title">
              <!--<h1>Digitalisez votre restaurant avec des outils sur mesure</h1>
              <h1>Faites de votre restaurant une expérience unique</h1>
              <h1>{{ 'landing.title' | translate }}</h1>
              <h1>Le <span>menu intelligent</span> qui <span>booste vos revenus</span></h1>-->
              <h1>Boostez vos <span>revenus</span> avec un menu intelligent <span>personnalisé</span></h1>
              <!--<h1>L'expérience restaurant.</h1>
              <h1>À votre image.</h1>
              <h2>{{ 'landing.subtitle' | translate }}</h2>
              <h2>Valorisez votre travail avec un menu digital premium à votre image.
              </h2>
              <h2>Augmentez votre chiffre d'affaire et valorisez votre travail avec un outil innovant !</h2>
              <h2>L’outil innovant qui vous permet de :
                <ul>
                  <li>S’ouvrir à une <b>clientèle internationale</b></li>
                  <li>Gagner du temps en <b>fluidifiant le service</b></li>
                  <li>Améliorer votre <b>visibilité sur internet</b></li>
                  <li><b>Fidéliser</b> en créant une expérience unique</li>
                </ul>
              </h2>-->
              <div class="hero-buttons-disp">
                <button type="button" routerLink="/auth/rest-signup" class="green_button green_button_border">{{ 'landing.button' | translate }}</button>
                <a href="https://meetings-eu1.hubspot.com/dcastiel" target="_blank">
                  <button type="button" class="green_button">{{ 'landing.ask-demo' | translate }}</button>
                </a>
              </div>
              <p class="button-subtitle">{{ 'landing.try-free' | translate }}</p>
            </div>
          </div>
          <div class="landing_view_side landing_view_right">
            <img src="assets/img/landing-page/landing-view-5001v0.3.png" alt="Phone Mockup">
          </div>
        </div>
        <!--<app-logos-section></app-logos-section>-->
      </div>
    </div>
  </div>
</div>

<div class="page_font">
  <div class="page_width_lp page_width_lp_green">
    <!--<app-features-multiple-tree></app-features-multiple-tree>-->
    <app-feature-single-digital-menu></app-feature-single-digital-menu>
    <app-landing-steps></app-landing-steps>
  </div>
  <app-feature-carousel></app-feature-carousel>
  <div class="page_width_lp page_width_lp_green">
  <app-features-multiple-digital-menu-big></app-features-multiple-digital-menu-big>
    <app-menu-examples-digital-menu></app-menu-examples-digital-menu>
    <!--<app-feature-single-paiement></app-feature-single-paiement>
    <app-feature-single-marketplace></app-feature-single-marketplace>
    <app-feature-single-room-service></app-feature-single-room-service>-->
  </div>
  <!--<app-feature-carousel></app-feature-carousel>-->
  <div class="page_width_lp page_width_lp_green">
    <app-our-restaurants></app-our-restaurants>
  </div>
  <div class="page_width_lp page_width_lp_green">
    <app-digital-menu-presentation-admin-single></app-digital-menu-presentation-admin-single>
    <app-project-price></app-project-price>
    <!--<app-digital-menu-admin-slidershow></app-digital-menu-admin-slidershow>-->
    <!--    <app-digital-menu-themes></app-digital-menu-themes>-->
    <!--<app-features-examples-digital-menu></app-features-examples-digital-menu>-->
    <!--
        <app-digital-menu-presentation-cta></app-digital-menu-presentation-cta>
    -->
    <app-features-multiples-other-services></app-features-multiples-other-services>
    <!-- Start of Meetings Embed Script -->
    <div class="meetings-iframe-container" data-src="https://meetings-eu1.hubspot.com/dcastiel?embed=true"></div>
    <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js" async></script>
    <!-- End of Meetings Embed Script -->
  </div>
  <app-cta-begin-today-big></app-cta-begin-today-big>
  <app-testimonial1></app-testimonial1>
  <app-stats-exp-client></app-stats-exp-client>
  <app-cta-create-your-account></app-cta-create-your-account>
</div>
<app-footer></app-footer>
<!--
<div class="how_diw">
  <div class="page_width_lp">
    <h2>{{ 'landing.hdiw' | translate }}</h2>
    &lt;!&ndash;<p class="h2_description">As a startup, we are going through the development cycle.</p>&ndash;&gt;
    <div class="how_diw_args_disp">
      <div class="how_diw_arg">
        <img src="assets/img/rest_covers/Number-1.svg" alt="Premier point">
        <h5>{{ 'landing.hdiw-creation' | translate }}</h5>
        <p>{{ 'landing.hdiw-creation-t' | translate }}</p>
      </div>
      <div class="how_diw_arg">
        <img src="assets/img/rest_covers/Number-2.svg" alt="Premier point">
        <h5>{{ 'landing.hdiw-share' | translate }}</h5>
        <p>{{ 'landing.hdiw-share-t' | translate }}</p>
      </div>
      <div class="how_diw_arg">
        <img src="assets/img/rest_covers/Number-3.svg" alt="Premier point">
        <h5>{{ 'landing.hdiw-scan' | translate }}</h5>
        <p>{{ 'landing.hdiw-scan-t' | translate }}</p>
      </div>
      <div class="how_diw_arg">
        <img src="assets/img/rest_covers/Number-4.svg" alt="Premier point">
        <h5>{{ 'landing.hdiw-modif' | translate }}</h5>
        <p>{{ 'landing.hdiw-modif-t' | translate }}</p>
      </div>
    </div>
  </div>
</div>

<div class="avantages">
  <div class="page_width_lp">
    <h2>{{ 'landing.advantages' | translate }}</h2>
    <div class="avantages_disp">
      <div class="avantages_list">
        <div class="avantages_list_disp">
          <div class="avantages_list_box" (click)="avFalse(); av1 = true;"  [ngStyle]="{'background-color': av1 ? '#eee' : '#fafafa'}">
            <div class="avantages_list_box_logo_box" [ngStyle]="{'background-color': av1 ? 'var(&#45;&#45;eatz-vert-fonce)' : '#ccc'}">
              <i class="bi bi-translate"></i>
            </div>
            <div class="avantages_list_box_description_box">
              <h3>{{ 'landing.advantages-trad' | translate }}</h3>
              <p>{{ 'landing.advantages-trad-t' | translate }}</p>
            </div>
            <div class="avantages_progress_bar" [ngStyle]="{'width': av1 ? '100%' : '0', 'opacity': av1 ? '1' : '0'}"></div>
          </div>
          <div class="avantages_list_box" (click)="avFalse(); av2 = true;"  [ngStyle]="{'background-color': av2 ? '#eee' : '#fafafa'}">
            <div class="avantages_list_box_logo_box" [ngStyle]="{'background-color': av2 ? 'var(&#45;&#45;eatz-vert-fonce)' : '#ccc'}">
              <i class="bi bi-brush-fill"></i>
            </div>
            <div class="avantages_list_box_description_box">
              <h3>{{ 'landing.advantages-mod' | translate }}</h3>
              <p>{{ 'landing.advantages-mod-t' | translate }}</p>
            </div>
            <div class="avantages_progress_bar" [ngStyle]="{'width': av2 ? '100%' : '0', 'opacity': av2 ? '1' : '0'}"></div>
          </div>
          <div class="avantages_list_box" (click)="avFalse(); av3 = true;"  [ngStyle]="{'background-color': av3 ? '#eee' : '#fafafa'}">
            <div class="avantages_list_box_logo_box" [ngStyle]="{'background-color': av3 ? 'var(&#45;&#45;eatz-vert-fonce)' : '#ccc'}">
              <i class="bi bi-image"></i>
            </div>
            <div class="avantages_list_box_description_box">
              <h3>{{ 'landing.advantages-photo' | translate }}</h3>
              <p>{{ 'landing.advantages-photo-t' | translate }}</p>
            </div>
            <div class="avantages_progress_bar" [ngStyle]="{'width': av3 ? '100%' : '0', 'opacity': av3 ? '1' : '0'}"></div>
          </div>
          <div class="avantages_list_box" (click)="avFalse(); av4 = true;"  [ngStyle]="{'background-color': av4 ? '#eee' : '#fafafa'}">
            <div class="avantages_list_box_logo_box" [ngStyle]="{'background-color': av4 ? 'var(&#45;&#45;eatz-vert-fonce)' : '#ccc'}">
              <i class="bi bi-phone-fill"></i>
            </div>
            <div class="avantages_list_box_description_box">
              <h3>{{ 'landing.advantages-mobile' | translate }}</h3>
              <p>{{ 'landing.advantages-mobile-t' | translate }}</p>
            </div>
            <div class="avantages_progress_bar" [ngStyle]="{'width': av4 ? '100%' : '0', 'opacity': av4 ? '1' : '0'}"></div>
          </div>
        </div>
      </div>
      <img src="assets/img/avantage_1.jpg" alt="Avantage 1" *ngIf="av1">
      <img src="assets/img/avantage_2.jpg" alt="Avantage 2" *ngIf="av2">
      <img src="assets/img/avantage_3.jpg" alt="Avantage 3" *ngIf="av3">
      <img src="assets/img/avantage_4.jpg" alt="Avantage 4" *ngIf="av4">
    </div>
  </div>
</div>

<div class="card_examples">
  <div class="page_width_lp">
    <h2>{{ 'landing.examples' | translate }}</h2>
    <div class="card_examples_disp">
      <a class="card_example" target="_blank" href="/menu/lou_ginestre_restaurant">
        <img src="assets/img/rest_covers/lou_ginestre.jpg" alt="Carte du restaurant Lou Ginestre">
        <p>
          {{ 'landing.examples-lou-ginestre' | translate }}
          <br>
          <i class="bi bi-arrow-right"></i>
        </p>
      </a>
      <a class="card_example" target="_blank" href="/menu/nJIBrGLuf0gsbM5ls48E">
        <img src="assets/img/rest_covers/sunset.jpg" alt="Carte du restaurant Le Sunset">
        <p>
          {{ 'landing.examples-sunset' | translate }}
          <br>
          <i class="bi bi-arrow-right"></i>
        </p>
      </a>
      <a class="card_example" target="_blank" href="/menu/XQCKkUFUlex2s3YFsPsT">
        <img src="assets/img/rest_covers/bergamotte.jpg" alt="Carte du restaurant Causette & Bergamotte">
        <p>
          {{ 'landing.examples-causette' | translate }}
          <br>
          <i class="bi bi-arrow-right"></i>
        </p>
      </a>
      <a class="card_example" target="_blank" href="/menu/CgjBwPzpbrA2LauasvHF">
        <img src="assets/img/rest_covers/khan.jpg" alt="Carte du restaurant Florence Khan">
        <p>
          {{ 'landing.examples-flo-khan' | translate }}
          <br>
          <i class="bi bi-arrow-right"></i>
        </p>
      </a>
    </div>
  </div>
</div>

<div class="price_section">
  <div class="price_box">
    <div class="price_section_half price_section_left">
      <h2>{{ 'landing.subscription' | translate }}</h2>
      <p>{{ 'landing.subscription-t' | translate }}</p>
      <a routerLink="/auth/rest-signup">{{ 'landing.subscription-button' | translate }} <i class="bi bi-arrow-right"></i></a>
    </div>
    <div class="price_section_half price_section_right">
      <img src="assets/img/rest_covers/admin_interface.png" alt="Phone Mockup">
    </div>
  </div>
</div>-->

<!--<div class="create_ya">
  <div class="create_ya_bg"></div>
  <div class="create_ya_content">
    <div>
      <div class="create_ya_half create_ya_left">
        <h2>Créez votre carte en 10 minutes, top chrono !</h2>
        <img src="assets/img/rest_covers/create_ya.jpg" alt="Une personne scanne un QR Code">
      </div>
      <div class="create_ya_half create_ya_right"></div>
    </div>
  </div>
</div>-->
<!--
<div class="create_section">
  <div class="create_section_bg">
    <div class="page_width_lp">
      <div class="create_ya_disp">
        <div class="create_ya_half create_ya_left">
          <h2>{{ 'landing.create-menu' | translate }}</h2>
          <img src="assets/img/rest_covers/create_ya.jpg" alt="Une personne scanne un QR Code">
        </div>
        <div class="create_ya_half create_ya_right">
          <div class="etapes_box">
            <div class="etape"  style="background-color: var(&#45;&#45;eatz-vert-fonce); color: #fff; cursor: pointer;" routerLink="/auth/rest-signup">
              <div class="etape_left">
                <div class="etape_logo_circle">
                  <img src="assets/img/rest_covers/10.svg">
                </div>
              </div>
              <div class="etape_right">
                <h3>{{ 'landing.create-menu-et1' | translate }}</h3>
                <p>{{ 'landing.create-menu-et1-t' | translate }}</p>
              </div>
            </div>
            <div class="etape">
              <div class="etape_left">
                <div class="etape_logo_circle">
                  <img src="assets/img/rest_covers/11.svg">
                </div>
              </div>
              <div class="etape_right">
                <h3>{{ 'landing.create-menu-et2' | translate }}</h3>
                <p>{{ 'landing.create-menu-et2-t' | translate }}</p>
              </div>
            </div>
            <div class="etape">
              <div class="etape_left">
                <div class="etape_logo_circle">
                  <img src="assets/img/rest_covers/12.svg">
                </div>
              </div>
              <div class="etape_right">
                <h3>{{ 'landing.create-menu-et3' | translate }}</h3>
                <p>{{ 'landing.create-menu-et3-t' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button_center">
      <button type="button" class="green_button" routerLink="/auth/rest-signup">{{ 'landing.create-menu-button' | translate }}</button>
    </div>
  </div>
</div>


<div class="stats_section">
  <div class="page_width_lp">
    <div class="stats_half stats_left">
      <h2>{{ 'landing.exp' | translate }}</h2>
      <p>{{ 'landing.exp-t' | translate }}</p>
    </div>
    <div class="stats_half">
      <div class="stats_disp">
        <div class="stat_box">
          <img src="assets/img/rest_covers/13.svg">
          <div class="stat_description">
            <h3>10,000+</h3>
            <p>{{ 'landing.exp-1' | translate }}</p>
          </div>
        </div>
        <div class="stat_box">
          <img src="assets/img/rest_covers/14.svg">
          <div class="stat_description">
            <h3>5 000</h3>
            <p>{{ 'landing.exp-2' | translate }}</p>
          </div>
        </div>
        <div class="stat_box">
          <img src="assets/img/rest_covers/15.svg">
          <div class="stat_description">
            <h3>50+</h3>
            <p>{{ 'landing.exp-3' | translate }}</p>
          </div>
        </div>
        <div class="stat_box">
          <img src="assets/img/rest_covers/16.svg">
          <div class="stat_description">
            <h3>140</h3>
            <p>{{ 'landing.exp-4' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="our_other_services">
  <div class="page_width_lp">
    <h2>{{ 'landing.our-services' | translate }}</h2>

    <div class="our_other_services_disp">
      <div class="our_other_services_half our_other_services_left">
        <img src="assets/img/rest_covers/our_other_products.png" alt="Nos autres services">
      </div>
      <div class="our_other_services_half our_other_services_right">
        <div class="our_other_services_right_arg">
          <h3><i style="color:var(&#45;&#45;eatz-vert-fonce); margin-right: 10px;">•</i>{{ 'landing.our-services-1' | translate }}</h3>
          <p>{{ 'landing.our-services-2-t' | translate }}</p>
        </div>
        <div class="our_other_services_right_arg">
          <h3><i style="color:var(&#45;&#45;eatz-rose-clair); margin-right: 10px;">•</i>{{ 'landing.our-services-2' | translate }}</h3>
          <p>{{ 'landing.our-services-2-t' | translate }}</p>
        </div>
        <div class="our_other_services_right_arg">
          <h3><i style="color:var(&#45;&#45;eatz-creme); margin-right: 10px;">•</i>{{ 'landing.our-services-3' | translate }}</h3>
          <p>{{ 'landing.our-services-3-t' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="create_your_account_section">
  <div class="page_width_lp">
    <h2>{{ 'landing.start-today' | translate }}</h2>
    <a routerLink="/auth/rest-signup">{{ 'landing.start-today-link' | translate }} <i class="bi bi-arrow-right"></i></a>
    <div class="button_center">
      <img src="assets/img/rest_covers/admin_screenshot.jpg" alt="Administration EAT'Z">
    </div>
  </div>
</div>-->

<!--<div class="contact_us">
  <form [formGroup]="ContactUsForm" (ngSubmit)="onSubmit()" *ngIf="!messageSent">

    <h2>{{ 'contact-us.title' | translate }}</h2>
    <h3>{{ 'contact-us.t' | translate }}</h3>

    <div class="form-group">
      <label for="name">{{ 'contact-us.name' | translate }}</label>
      <input type="text" class="form-control" id="name" formControlName="name" placeholder="John Dupond">
      <p class="error_message" *ngIf="user_name.invalid && user_name.touched">
        {{ 'contact-us.name-error' | translate }}
      </p>
    </div>
    <div class="form-group">
      <label for="email">{{ 'contact-us.email' | translate }}</label>
      <input type="email" class="form-control" id="email" formControlName="email" placeholder="info@exemple.com">
      <p class="error_message" *ngIf="email.invalid && email.touched">
        {{ 'contact-us.email-error' | translate }}
      </p>
    </div>
    <div class="form-group">
      <label for="phone">{{ 'contact-us.phone' | translate }}</label>
      <input type="tel" class="form-control" id="phone" formControlName="phone" placeholder="+33 6 10 20 30 40">
      <p class="error_message" *ngIf="phone.invalid && phone.touched">
        {{ 'contact-us.phone-error' | translate }}
      </p>
    </div>
    <div class="form-group">
      <label for="message">{{ 'contact-us.message' | translate }}</label>
      <textarea name="message" id="message" formControlName="message" placeholder="{{ 'contact-us.message-error' | translate }}">
            </textarea>
      <p class="error_message" *ngIf="message.invalid && message.touched">
        {{ 'contact-us.message-error' | translate }}
      </p>
    </div>
    <div class="align_submit_button">
      <button [disabled]="ContactUsForm.invalid" class="btn" type="submit">{{ 'contact-us.send' | translate }}</button>
    </div>
  </form>

  <div *ngIf="messageSent">
    <h2 style="margin-bottom: 15px;">Message envoyé</h2>
    <h3>Nous avons bien reçu votre message, nous vous contacterons dans les plus bref délais</h3>
    <i class="bi bi-check-all"></i>
    <div class="align_submit_button">
      <button class="green_button" id="back_button" routerLink="/auth/rest-signup">Créer ma carte</button>
    </div>
  </div>
</div>-->
