import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NewLandingPageComponent} from './new-landing-page/new-landing-page.component';
import {DigitalMenuPresentationComponent} from './digital-menu-presentation/digital-menu-presentation.component';
import {HomeComponent} from './home.component';
import {PaiementPresentationComponent} from './paiement-presentation/paiement-presentation.component';
import {PricesComponent} from './prices/prices.component';
import {HomeMarketplaceComponent} from './home-marketplace/home-marketplace.component';


const routes: Routes = [
  { path: '', component: HomeComponent,
    children: [
      { path: '', component: NewLandingPageComponent },
      { path: 'prices', component: PricesComponent },
      { path: 'menu-qr', component: DigitalMenuPresentationComponent },
      { path: 'paiement', component: PaiementPresentationComponent },
      { path: 'marketplace', component: HomeMarketplaceComponent },
    ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
