<router-outlet></router-outlet>

<!-- The core Firebase JS SDK is always required and must be listed first -->
<!--
<script src="/__/firebase/7.15.1/firebase-app.js" async></script>
-->

<!-- TODO: Add SDKs for Firebase products that you want to use
     https://firebase.google.com/docs/web/setup#available-libraries -->
<!--
<script src="/__/firebase/7.15.1/firebase-analytics.js" async></script>
-->

<!-- Initialize Firebase -->
<!--
<script src="/__/firebase/init.js" async></script>
-->
