import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import * as firebase from "firebase";
import {Subscription} from 'rxjs';
import {AdminService} from '../../../services/admin.service';
import {AuthService} from '../../../services/auth.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() transparent: boolean = false;

  header_menu: boolean = false;
  chat_bubble: boolean = false;
  isAuth: boolean = false;

  adminCache: any = [];
  adminCacheSubscription: Subscription;

  UserCache: any = [];
  UserCacheSubscription: Subscription;
  lang_modal = false;

  header: boolean;
  lastScrollTop = 0;

  constructor(private adminService: AdminService,
              private authService: AuthService,
              private translate: TranslateService) {}

  lang = this.translate.currentLang;

  ngOnInit(): void {
    if (!this.transparent) {this.header = true}
    if (this.lang === undefined) {
      if (['fr', 'en', 'it', 'es', 'de', 'he', 'zh', 'ru', 'ar', 'pt'].indexOf(this.translate.getBrowserLang()) !== -1) {
        this.lang = this.translate.getBrowserLang();
      } else {
        this.lang = 'en';
      }
    }
    //Get the admin cache Subscription
    this.adminCacheSubscription = this.adminService.adminCacheSubject.subscribe(
      (adminCache: any[]) => {
        this.adminCache = adminCache;

        const user = firebase.default.auth().currentUser;
        if (user) {
          this.isAuth = true;
        }
      }
    );
    this.adminService.emitAdminCacheSubject();

    this.UserCacheSubscription = this.authService.userCacheSubject.subscribe(
      (UserCache: any[]) => {
        this.UserCache = UserCache;
      }
    );
    this.authService.emitUserCacheSubject();
  }

  update_lang(language: string): void {
    this.translate.use(language);
    this.lang_modal = false;
    this.lang = language;
  }

  ngOnDestroy() {
    this.adminCacheSubscription.unsubscribe();
    this.UserCacheSubscription.unsubscribe();
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (this.transparent) {
      this.header = number >= 50;
    } else {
      this.header = true;
    }
  }

}
