<div class="page_font">
  <div class="admin-presentation-text-box">
    <p class="admin-presentation-title">{{ 'landing.menu-qr.admin-title' | translate }}</p>
    <p class="admin-presentation-description">{{ 'landing.menu-qr.admin-p' | translate }}</p>
    <a routerLink="/auth/rest-signup">{{ 'landing.menu-qr.admin-cta' | translate }} <i class="bi bi-arrow-right"></i></a>
  </div>

  <div class="admin-presentation-bg">
    <img id="photo-2" src="assets/img/landing-page/digital-menu-admin-2-h630.png" alt="Interface Administration">
    <img id="photo-1" src="assets/img/landing-page/digital-menu-admin-1-h630.jpg" alt="Interface Administration">
  </div>
</div>
