import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stats-exp-client',
  templateUrl: './stats-exp-client.component.html',
  styleUrls: ['./stats-exp-client.component.scss']
})
export class StatsExpClientComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
