import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-feature-single-room-service',
  templateUrl: './feature-single-room-service.component.html',
  styleUrls: ['./feature-single-room-service.component.scss']
})
export class FeatureSingleRoomServiceComponent implements OnInit {
  squareH: number;

  constructor() { }

  ngOnInit(): void {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.squareH = (window.innerWidth - 30) / Math.sqrt(2);
  }

}
