import {Component, Input, OnInit} from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isAuth: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if (firebase.default.auth().currentUser) {
      this.isAuth = true;
    }
  }

}
