import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-menu-presentation-admin-single',
  templateUrl: './digital-menu-presentation-admin-single.component.html',
  styleUrls: ['./digital-menu-presentation-admin-single.component.scss']
})
export class DigitalMenuPresentationAdminSingleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
